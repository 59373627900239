import React from 'react';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collections, useAuth } from '../../FirebaseHooks';
import { addDoc, collection, getFirestore, setDoc, doc, Timestamp, runTransaction } from 'firebase/firestore';
import { base62uuid, Db, DbMember, DbProject, full, PlayConfig, stringifyMemberId, stringifyOwnerId } from 'dims-shared';
import { KeyboardColumn, KeyboardRow, Page, Row } from '../../Components/Layout';
import { RoutingHistory } from '../../Components/Routing';
import { Button } from '../../Components/Button';
import { PlayConfigEditor } from '.';
import slugify from 'slugify';
import { useValidateNamespace } from '../Organization';
import { ValidationCheckmark } from '../../Components/Form';

const db = getFirestore(getApp());
const functions = getFunctions(getApp());

const getDimsVersions = httpsCallable<{}, string[]>(functions, 'getDimsVersions');


export function NewProjectPage() {
    let [name, setName] = React.useState('');
    let [namespace, setNamespace] = React.useState('');
    let namespaceError = useValidateNamespace(namespace);
    let [playConfig, setPlayConfig] = React.useState<PlayConfig>({ type: 'one-off' });
    let auth = useAuth();

    let [version, setVersion] = React.useState('');

    if (!auth) {
        return <div>Not logged in</div>;
    }
    let valid = !!name && !!version;
    return <Page><KeyboardColumn>
        <h2>New project</h2>
        <Row>
            <DimsVersionSelect value={version} onChange={setVersion} selectLatestOnLoad={true} />
        </Row>

        <KeyboardRow>Name <input
            type="text"
            placeholder='Project name'
            value={name}
            onChange={e => {
                setName(e.target.value); setNamespace(slugify(e.target.value, {
                    replacement: '_',
                    strict: true,
                    lower: true
                }))
            }}
        /></KeyboardRow>
        <KeyboardRow>Namespace
            <input type="text" placeholder='Namespace' value={namespace} onChange={e => setNamespace(e.target.value)} />
            <ValidationCheckmark valid={namespaceError === null} tooltip={namespaceError ?? 'Namespace ok'} />
        </KeyboardRow>
        <PlayConfigEditor value={playConfig} onChange={setPlayConfig} />
        <Row><Button primary={true} disabled={!valid} onClick={async () => {
            let projId = base62uuid();
            let res = await runTransaction(db, async t => {
                const namespaceRef = doc(collections.namespaces, namespace);
                if ((await t.get(namespaceRef)).exists()) {
                    return false;
                }
                t.set(namespaceRef, { ownerId: stringifyOwnerId('prj', projId) });
                t.set(doc(collections.projects, projId), { name, namespace, appVersion: version, isCreated: false, description: '', playConfig });
                return true;
            });
            if (res === false) {
                return;
            }
            await setDoc(doc(collections.members, stringifyMemberId('project', projId, auth!.uid)), {
                type: 'project',
                itemId: projId,
                userId: auth!.uid,
                isAdmin: true,
                created: Timestamp.now(),
                sidebarOrder: Date.now()
            });
            RoutingHistory.push(`/projects/${projId}`);
        }} >Create project</Button></Row>
    </KeyboardColumn>
    </Page>;
}

export function DimsVersionSelect({ value, onChange, selectLatestOnLoad = false }: { value: string, onChange: (value: string) => void, selectLatestOnLoad?: boolean }) {
    let [versions, setVersions] = React.useState<string[] | null>(null);
    React.useEffect(() => {
        (async () => {
            let versions = (await getDimsVersions({})).data;
            setVersions(versions);
            if (selectLatestOnLoad) {
                onChange(versions[0]);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (versions === null) {
        return <Row>Loading...</Row>;
    }
    return <select value={value} onChange={e => onChange(e.target.value)}>
        {versions.map(version => <option value={version}>{version}</option>)}
        <option value="canary">Dev</option>
    </select>
}
