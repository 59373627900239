import './index.css';
import { getApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useMemo, useState } from 'react';
import { collections, queryT, useStreamDocumentById, useStreamQuery, whereT } from '../../FirebaseHooks';
import { Column, KeyboardColumn } from '../Layout';
import { StringEditor } from '../Editor';
import { limit } from 'firebase/firestore';
import { Loading } from '../Loading';
import { Button } from '../Button';
import { DialogScreen, ScreenContainer, ScreenType } from '../Screen';
import { Dialog } from '../Dialog';
import { intoPromise } from '../../Util';

const storage = getStorage(getApp());

export function UserName({ userId }: { userId: string }) {
    let profile = useStreamDocumentById(collections.profiles, userId);
    if (profile.status === 'loading') {
        return <>...</>;
    } else if (profile.status === 'loaded') {
        return <>{profile.value.displayName}</>
    } else {
        return <>{userId}</>
    }
}

export function useUserProfileImage(userId: string) {
    let profile = useStreamDocumentById(collections.profiles, userId);
    let [imageUrl, setImageUrl] = React.useState<null | string>(null);
    React.useEffect(() => {
        if (profile.status === 'loaded' && profile.value.imagePath) {
            (async () => {
                setImageUrl(await getDownloadURL(ref(storage, profile.value.imagePath)));
            })();
        } else {
            setImageUrl(null);
        }
    }, [profile]);
    return imageUrl;
}

export function UserProfileImage({ userId, large = false }: { userId: string, large?: boolean }) {
    let image = useUserProfileImage(userId);
    let className = ['UserProfileImage', large ? 'UserProfileImageLarge' : ''].join(' ');
    if (image) {
        return <img className={className} src={image} alt="Profile" />
    } else {
        return <div className={className} />
    }
}

export function SearchUser({ onSelect }: { onSelect: (userId: string) => void | Promise<void> }) {
    let [search, setSearch] = useState('');
    let users = useStreamQuery(useMemo(() =>
        queryT(collections.profiles, whereT('displayName', '>=', search), whereT('displayName', '<=', search + '\uf8ff'), limit(5)),
        [search]));
    return <KeyboardColumn>
        <StringEditor value={search} onChange={setSearch} placeholder="Search user name" />
        <Loading values={{ users }}>{({ users }) => <KeyboardColumn>
            {users.map(user => <Button key={user.id} onClick={async () => await intoPromise(onSelect(user.id))}>{user.displayName}</Button>)}
        </KeyboardColumn>}</Loading>
    </KeyboardColumn>
}

export function SearchUserDialog({ onSelect }: { onSelect: (userId?: string) => void | Promise<void> }) {
    return <DialogScreen onClose={() => onSelect()}><Dialog>
        <SearchUser onSelect={onSelect} />
    </Dialog></DialogScreen>
}

export function SelectUserButton({ onSelect, children }: { onSelect: (userId?: string) => void | Promise<void>, children?: React.ReactNode }) {
    let [screen, setScreen] = useState<ScreenType>(null);
    return <Button onClick={() => setScreen(<SearchUserDialog onSelect={async user => { await onSelect(user); setScreen(null); }} />)}>
        <ScreenContainer screen={screen} />
        {children ?? 'Select user'}
    </Button>
}
