import './FirebaseConfig';
import React from 'react';
import './App.css';
import { Router, RoutingHistory } from './Components/Routing';
import { FirebaseLogin } from './Components/FirebaseLogin';
import { getAuth } from 'firebase/auth';
import { AdminPage } from './Pages/Admin';
import { MainPage } from './Pages/Main';
import { ScreensRoot } from './Components/Screen';
import { AdminServers } from './Pages/Admin/Servers';
import { AppAssetBrowser } from './Pages/AppAssetBrowser';

export type LauncherState = { state: 'running', port: number } | { state: 'not-running' } | { state: 'loading' };
export const LauncherContext = React.createContext<LauncherState>({ state: 'loading' });

const auth = getAuth();

function LoginPage() {
    return <FirebaseLogin onSignedIn={() => RoutingHistory.push('/')} />;
}

function LauncherContextProvider({ children }: { children?: React.ReactNode }) {
    let [launcherRunning, setLauncherRunning] = React.useState<LauncherState>({ state: 'loading' });
    React.useEffect(() => {
        let curState: { value: LauncherState } = { value: { state: 'loading' } };
        let handle = setInterval(() => {
            let port: number = 10723;
            let update = (state: LauncherState) => {
                if (JSON.stringify(state) !== JSON.stringify(curState.value)) {
                    setLauncherRunning(state);
                    curState.value = state;
                }
            }
            fetch(`https://dims-launcher.com:${port}/ping`).then(res => {
                if (res.status === 200) {
                    update({ state: 'running', port });
                } else {
                    update({ state: 'not-running' });
                }
            }).catch(err => {
                update({ state: 'not-running' });
            });
        },
            1000);
        return () => {
            clearInterval(handle);
        }
    }, []);
    return <LauncherContext.Provider value={launcherRunning}>{children}</LauncherContext.Provider>
}

function App() {
    return (
        <>
            <Router routes={{
                '/admin': () => <AdminPage />,
                '/login': () => <LoginPage />,
                '/app_asset_browser/:projectId/:type': (match) => <AppAssetBrowser type={match.type} projectId={match.projectId} />,
            }} notFound={() => <LauncherContextProvider><MainPage /></LauncherContextProvider>} />
            <ScreensRoot />
        </>
    );
}

export default App;
