import { formatRelative } from "date-fns";
import { Db, DbId, DbProject, DbRelease, ReqCreateRelease, ReqResetToRelease } from "dims-shared";
import { getApp } from "firebase/app";
import { addDoc, collection, CollectionReference, deleteDoc, doc, query, Timestamp, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { useMemo, useState } from "react";
import * as semver from 'semver';
import { Button } from "../../Components/Button";
import { Dialog, Prompt } from "../../Components/Dialog";
import { PromptingButton, ConfirmingButton, EditableString } from "../../Components/Dialog/Buttons";
import { Dropdown } from "../../Components/Dropdown";
import { StringEditor } from "../../Components/Editor";
import { KeyboardColumn, KeyboardRow, Panel, Row } from "../../Components/Layout";
import { Loading } from "../../Components/Loading";
import { Menu } from "../../Components/Menu";
import { DialogScreen, ScreenContainer, ScreenType } from "../../Components/Screen";
import { collections, orderByT, queryT, useStreamQuery, whereT } from "../../FirebaseHooks";

const functions = getFunctions(getApp());
const projectsStorage = getStorage(getApp(), "gs://dims-projects");
const createRelease = httpsCallable<ReqCreateRelease, string>(functions, 'createRelease');
const resetToRelease = httpsCallable<ReqResetToRelease, string>(functions, 'resetToRelease');

export function ProjectReleases({ project }: { project: DbProject & DbId }) {
    let releases = useStreamQuery(useMemo(() => queryT(collections.releases(project.id), orderByT('created', 'desc')), [project.id]));
    return <Loading values={{ releases }}>{({ releases }) => <KeyboardColumn style={{ alignItems: 'start' }}>
        {releases.map(release => <Release key={release.id} release={release} project={project} />)}
    </KeyboardColumn>}</Loading>
}

function Release({ release, project }: { release: DbRelease & DbId, project: DbProject & DbId }) {
    let releaseDoc = doc(collections.releases(project.id), release.id);
    let opts = () => <Menu>
        {release.id !== project.releaseId && <ConfirmingButton onClick={async () => {
            await updateDoc(doc(collections.projects, project.id), { releaseId: release.id });
        }}>Make current release</ConfirmingButton>}
        <ConfirmingButton onClick={async () => {
            await resetToRelease({ projectId: project.id, version: release.version });
        }}>Reset working version to this release</ConfirmingButton>
        <ConfirmingButton onClick={async () => {
            await deleteObject(ref(projectsStorage, `projects/${project.id}/${release.version}.json`));
            await deleteDoc(releaseDoc);
        }}>Delete</ConfirmingButton>
    </Menu>;
    return <KeyboardColumn>
        <KeyboardRow style={{ alignItems: 'center' }}>
            <h3 className="KeyboardRow">
                <span className="ReleaseVersion">{release.version}</span>
                <EditableString onOk={async title => await updateDoc(releaseDoc, { title })}>
                    {release.title}
                </EditableString>
            </h3>

            {release.id === project.releaseId && <i className="fa-solid fa-circle-arrow-left"></i>}
        </KeyboardRow>
        <KeyboardRow>
            <div>{formatRelative(release.created.toDate(), new Date())}</div>
            {release.appVersion}
            <Dropdown dropdown={opts}>
                <i className="fa-solid fa-gear"></i>
            </Dropdown>
        </KeyboardRow>

    </KeyboardColumn>
}

export function NewReleaseDialog({ onClose, project }: { onClose: () => void, project: DbProject & DbId }) {
    let [mode, setMode] = useState<semver.ReleaseType>('minor');
    let [title, setTitle] = useState('');
    let releases = useStreamQuery(useMemo(() => queryT(collections.releases(project.id), orderByT('created', 'desc')), [project.id]));
    let latestRelease = releases.status === 'loaded' ? releases.value[0] : null;
    let version = semver.inc(latestRelease?.version ?? '0.0.0', mode);
    return <DialogScreen onClose={onClose}><Dialog>
        <KeyboardColumn>
            <h2>New release</h2>
            <KeyboardRow>
                Version: {version}
                <Button flat={true} toggled={mode === 'major'} onClick={() => setMode('major')}>Major</Button>
                <Button flat={true} toggled={mode === 'minor'} onClick={() => setMode('minor')}>Minor</Button>
                <Button flat={true} toggled={mode === 'patch'} onClick={() => setMode('patch')}>Patch</Button>
            </KeyboardRow>
            <KeyboardRow>
                Title: <StringEditor value={title} onChange={setTitle} placeholder="What's new in this version?" />
            </KeyboardRow>
            <KeyboardRow>
                <Button primary={true} onClick={async () => {
                    if (!version) {
                        return;
                    }
                    await createRelease({ projectId: project.id, version });
                    let release = await addDoc(collections.releases(project.id), {
                        projectId: project.id,
                        created: Timestamp.now(),
                        version,
                        appVersion: project.appVersion,
                        title
                    });
                    await updateDoc(doc(collections.projects, project.id), { releaseId: release.id });
                    onClose();
                }}>
                    Publish
                </Button>
                <Button flat={true} onClick={() => onClose()}>Cancel</Button>
            </KeyboardRow>
        </KeyboardColumn>
    </Dialog></DialogScreen>
}
