
export type Loadable<T, E = any> =
    { status: 'loading' } | { status: 'loaded', value: T } | { status: 'error', error: E, message?: string };


export type EventListener<T extends Array<any>> = (...event: T) => void;
export class EventDispatcher<T extends Array<any>> {
    listeners: Array<EventListener<T>> = [];
    dispatch(...event: T) {
        for (const listener of this.listeners) {
            listener(...event);
        }
    }
    attach(listener: EventListener<T>) {
        this.listeners.push(listener);
    }
    detach(listener: EventListener<T>) {
        this.listeners.splice(this.listeners.indexOf(listener), 1);
    }
    transform<T2 extends Array<any>>(map: (...event: T) => T2) {
        const dispatcher = new EventDispatcher<T2>();
        this.attach((...event) => dispatcher.dispatch(...map(...event)));
        return dispatcher;
    }
}

export function intoPromise<T>(value: T | Promise<T>): Promise<T> {
    if (value instanceof Promise) {
        return value;
    } else {
        return Promise.resolve(value);
    }
}

export function arrayToggle<T>(array: T[], value: T) {
    let arr = [...array];
    let index = arr.findIndex(x => x === value);
    if (index >= 0) {
        arr.splice(index, 1);
    } else {
        arr.push(value);
    }
    return arr;
}

export function arrayReplaceIndex<T>(array: T[], index: number, value: T): T[] {
    const a2 = [...array];
    a2[index] = value;
    return a2;
}
export function arrayInsertIndex<T>(array: T[], index: number, value: T): T[] {
    return [...array.slice(0, index), value, ...array.slice(index)];
}
export function arrayRemoveIndex<T>(array: T[], index: number): T[] {
    return [...array.slice(0, index), ...array.slice(index + 1)];
}
export function arrayMove<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    const arr = array.slice();
    const [el] = arr.splice(fromIndex, 1);
    arr.splice(Math.max(toIndex, 0), 0, el);
    return arr;
}

export type Option<T> = { Some: T } | 'None';

export function none<T>(): Option<T> {
    return 'None';
}
export function some<T>(value: T): Option<T> {
    return { Some: value };
}
export function ifLet<T>(value: Option<T>, then: (value: T) => void) {
    if (value !== 'None') {
        then(value.Some);
    }
}

// From https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
export function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
        return 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        return 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'Windows';
    } else if (/Android/.test(userAgent)) {
        return 'Android';
    } else if (/Linux/.test(platform)) {
        return 'Linux';
    } else {
        return 'Unknown';
    }
}
