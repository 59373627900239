import { Db, DbMessage, stringifyOwnerId, stringifyMemberId, DbOrganization, DbId, base62uuid } from "dims-shared";
import { getApp } from "firebase/app";
import { addDoc, collection, CollectionReference, doc, getDoc, getDocs, getFirestore, onSnapshot, query, runTransaction, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import React from "react";
import { useEffect, useMemo, useState, version } from "react";
import slugify from "slugify";
import { AssetPackList, PipelineJobs } from "../Components/AssetDatabase";
import { Button } from "../Components/Button";
import { Chat } from "../Components/Chat";
import { ECSComponentsForOwner } from "../Components/ECSComponents";
import { ValidationCheckmark } from "../Components/Form";
import { Page, KeyboardColumn, KeyboardRow, Row } from "../Components/Layout";
import { Loading } from "../Components/Loading";
import { MemberEditor } from "../Components/Relationships";
import { RoutingHistory } from "../Components/Routing";
import { ScreenContainer, ScreenType } from "../Components/Screen";
import { Tab, Tabs } from "../Components/Tabs";
import { useAuth, collections, useStreamDocumentById, queryT, whereT, useStreamQuery, useStreamDocument, orderByT } from "../FirebaseHooks";
import { Loadable } from "../Util";
import { SplitMain, SplitMainLeft, SplitMainRight } from "./Main";

const db = getFirestore(getApp());

export function OrganizationPage({ organizationId }: { organizationId: string }) {
    let chatCollection = useMemo(() => collection(doc(collections.projects, organizationId), Db.messages) as CollectionReference<DbMessage>, [organizationId]);
    let assetsBaseQuery = useMemo(() => queryT(collections.assetPacks, whereT('ownerId', '==', stringifyOwnerId('org', organizationId))), [organizationId]);
    let jobsQuery = useMemo(() => queryT(collections.pipelineJobs,
        whereT('ownerId', '==', stringifyOwnerId('org', organizationId)),
        orderByT('created', 'desc')
    ), [organizationId]);

    return <Loading values={{ org: useStreamDocumentById(collections.organizations, organizationId) }}>{({ org }) => <SplitMain>
        <SplitMainLeft>
            <h2>{org.name}</h2>
            <Tabs style={{ flex: 1, overflow: 'hidden' }}>
                <Tab label="Asset packs">
                    <AssetPackList
                        baseQuery={assetsBaseQuery}
                        ownerId={stringifyOwnerId('org', organizationId)}
                    />
                </Tab>
                <Tab label="Jobs">
                    <KeyboardRow style={{ flex: 1, overflow: 'auto' }}>
                        <PipelineJobs query={jobsQuery} showAsset={true} />
                    </KeyboardRow>
                </Tab>
                <Tab label="Members">
                    <MemberEditor memberType='organization' itemId={organizationId} />
                </Tab>
                <Tab label="Components">
                    <ECSComponentsForOwner ownerId={stringifyOwnerId('org', org.id)} />
                </Tab>
            </Tabs>
        </SplitMainLeft>
        <SplitMainRight>
            <Chat collection={chatCollection} />
        </SplitMainRight>
    </SplitMain>}</Loading>;
}

export function useValidateNamespace(namespace: string) {
    let ns = useStreamDocument(useMemo(() => doc(collections.namespaces, namespace.length > 0 ? namespace : 'empty'), [namespace]));
    if (!(namespace.length > 0 && /^[a-z0-9_]*$/.test(namespace))) {
        return "Namespace must be alphanumeric";
    } else if (ns.status === 'loading') {
        return "Checking if namespace is taken...";
    } else if (ns.status === 'error') {
        if (ns.error === 'no-such-document') {
            return null; // Ok
        } else {
            return "Failed to get namespace";
        }
    } else {
        return "Namespace is taken";
    }
}

export function NewOrganizationPage() {
    let [name, setName] = useState('');
    let [namespace, setNamespace] = useState('');
    let namespaceError = useValidateNamespace(namespace);
    let auth = useAuth();

    if (!auth) {
        return <div>Not logged in</div>;
    }
    let valid = !!name && namespaceError === null;
    return <Page><KeyboardColumn>
        <h2>New organization</h2>

        <KeyboardRow>Name <input
            type="text"
            placeholder='Org name'
            value={name}
            onChange={e => {
                setName(e.target.value); setNamespace(slugify(e.target.value, {
                    replacement: '_',
                    strict: true,
                    lower: true
                }))
            }}
        /></KeyboardRow>
        <KeyboardRow>Namespace
            <input type="text" placeholder='Namespace' value={namespace} onChange={e => setNamespace(e.target.value)} />
            <ValidationCheckmark valid={namespaceError === null} tooltip={namespaceError ?? 'Namespace ok'} />
        </KeyboardRow>
        <Row><Button primary={true} disabled={!valid} onClick={async () => {
            let orgId = base62uuid();
            let res = await runTransaction(db, async t => {
                const namespaceRef = doc(collections.namespaces, namespace);
                if ((await t.get(namespaceRef)).exists()) {
                    return false;
                }
                t.set(namespaceRef, { ownerId: stringifyOwnerId('org', orgId) });
                t.set(doc(collections.organizations, orgId), { name, created: Timestamp.now(), namespace, assetTags: [] });
                return true;
            });
            if (res === false) {
                return;
            }
            setDoc(doc(collections.members, stringifyMemberId('organization', orgId, auth!.uid)), {
                type: 'organization',
                itemId: orgId,
                userId: auth!.uid,
                created: Timestamp.now(),
                isAdmin: true,
                sidebarOrder: Date.now()
            });
            RoutingHistory.push(`/organizations/${orgId}`);
        }} >Create organization</Button></Row>
    </KeyboardColumn>
    </Page>;
}
