import { DbMember, DbMemberType, parseOwnerId, stringifyMemberId } from "dims-shared";
import { CollectionReference, doc, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import { useMemo } from "react";
import { collections, queryT, QueryT, useStreamDocumentById, useStreamQuery, whereT } from "../FirebaseHooks";
import { CheckboxEditor } from "./Editor";
import { KeyboardColumn, Row } from "./Layout";
import { Loading } from "./Loading";
import { SelectUserButton, UserName } from "./User";

export function OwnerName({ ownerId }: { ownerId: string }) {
    let { type, id } = parseOwnerId(ownerId);
    if (type === 'user') {
        return <UserName userId={id} />;
    } else if (type === 'org') {
        return <DocumentName collection={collections.organizations} id={id} />;
    } else {
        return <>Unrecognized owner type: {type}</>;
    }
}

export function DocumentName({ collection, id }: { collection: CollectionReference<{ name: string }>, id: string }) {
    let profile = useStreamDocumentById(collection, id);
    if (profile.status === 'loading') {
        return <>...</>;
    } else if (profile.status === 'loaded') {
        return <>{profile.value.name}</>
    } else {
        return <>{id}</>
    }
}

export function MemberEditor({ memberType, itemId }: { memberType: DbMemberType, itemId: string }) {
    let members = useStreamQuery(useMemo(() => queryT(collections.members, whereT('type', '==', memberType), whereT('itemId', '==', itemId)), [memberType, itemId]));
    return <Loading values={{ members }}>{({ members }) => <KeyboardColumn style={{ alignItems: 'start' }}>
        <SelectUserButton onSelect={async userId => {
            if (userId) {
                let d = doc(collections.members, stringifyMemberId(memberType, itemId, userId));
                await setDoc(d, {
                    type: memberType,
                    itemId,
                    userId,
                    created: Timestamp.now(),
                    isAdmin: true,
                    sidebarOrder: Date.now(),
                });
            }
        }} >Add member</SelectUserButton>
        <table><tbody>
            <tr><th>Name</th><th>Admin</th></tr>
            {members.map(member => <tr key={member.id}>
                <td><UserName userId={member.userId} /></td>
                <td><CheckboxEditor value={member.isAdmin} onChange={async isAdmin => updateDoc(doc(collections.members, member.id), { isAdmin })} /></td>
            </tr>)}
        </tbody></table>
    </KeyboardColumn>}</Loading>;
}
