import './Main.css';
import { Center, Column, KeyboardColumn, Page, Panel, Row } from '../Components/Layout';
import { Link, Router, useRoute } from '../Components/Routing';
import { collections, orderByT, queryT, useAuth, useAuthLoading, useStreamDocumentById, useStreamQuery, whereT } from '../FirebaseHooks';
import { ProjectMain, useProjectThumbnailUrl } from './Project';
import { getIdToken, User } from 'firebase/auth';
import { NewProjectPage } from './Project/New';
import { UserPage } from './User';
import { EditLiveIndicator } from './Project/Instances';
import { FirebaseLogin } from '../Components/FirebaseLogin';
import { FirstTimeSetupUserProfile, LoginInfo } from '../Components/MyProfile';
import React from 'react';
import { Button } from '../Components/Button';
import { Dropdown, Tooltip } from '../Components/Dropdown';
import { NewOrganizationPage, OrganizationPage } from './Organization';
import { Loading } from '../Components/Loading';
import { deleteDoc, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { DbMemberType } from 'dims-shared';
import { Menu } from '../Components/Menu';

export function MainPage() {
    let auth = useAuthLoading();
    if (!auth) {
        return <Center><FirebaseLogin /></Center>;
    }
    if (auth === 'loading') {
        return null;
    }
    return <Row className="Main">
        <LeftSidebar user={auth} />

        <Router routes={{
            '/': () => <Row>No project selected</Row>,
            '/projects/new': match => <NewProjectPage />,
            '/projects/:id': match => <ProjectMain projectId={match.id} />,
            '/organizations/new': match => <NewOrganizationPage />,
            '/organizations/:id': match => <OrganizationPage organizationId={match.id} />,
            '/users/:id': match => <UserPage userId={match.id} />,
        }} notFound={() => <Page>404 Not found</Page>} />
        <FirstTimeSetupUserProfile auth={auth} />
    </Row>
}

function LeftSidebar({ user }: { user: User }) {
    let memberships = useStreamQuery(React.useMemo(() => queryT(collections.members, whereT('userId', '==', user.uid), orderByT('sidebarOrder', 'desc')), [user.uid]));
    let route = useRoute();
    return <Column className='LeftSidebar'>
        <SidebarList>
            <Loading values={{ memberships }}>{({ memberships }) => {
                // Split organizations and projects, and render organizations first.
                let organizations = memberships.filter(a => a.type === "organization");
                let projects = memberships.filter(a => a.type === "project");

                let separator = organizations.length > 0 && projects.length > 0 ? <hr /> : null;

                return [
                    organizations.map((membership) => (
                        <OrganizationItem
                            key={membership.id}
                            organizationId={membership.itemId}
                        />
                    )),
                    separator,
                    projects.map((membership) => (
                        <ProjectItem
                            key={membership.id}
                            projectId={membership.itemId}
                        />
                    )),
                ].flat();
            }}</Loading>
            <SidebarItemNew />
        </SidebarList >
        <LoginInfo user={user} />
    </Column>
}

export function SidebarList({ children }: { children?: React.ReactNode }) {
    return <KeyboardColumn className='SidebarList ScrollArea'>{children}</KeyboardColumn>
}
export function SidebarItem({ link, title, selected = false, image, children }: { link: string, title: string, selected?: boolean, image?: string, children?: any }) {
    return <Row className={`SidebarItem KeyboardRow ${selected ? 'SidebarItemSelected' : ''}`}>
        <div className='SidebarItemLeft' />
        <Tooltip attachment='horizontal' tooltip={() => <Row>{title}</Row>}>
            <Button flat={true} link={link} className="SidebarItemButton"
                style={{ backgroundImage: image ? `url(${image})` : undefined }}
            >{children}</Button>
        </Tooltip>
    </Row>;
}
export function SidebarItemNew() {
    let dropdown = () => <Menu>
        <Button link='/projects/new'>New project</Button>
        <Button link='/organizations/new'>New organization</Button>
    </Menu>;
    return <Row className={`SidebarItem KeyboardRow`}>
        <div className='SidebarItemLeft' />
        <Dropdown dropdown={dropdown} attachment='horizontal' flat={true}>
            <Tooltip attachment='horizontal' tooltip={() => <Row>New project or organization</Row>}>
                <Button flat={true} className="SidebarItemButton"
                ><i className="fa-solid fa-circle-plus"></i></Button>
            </Tooltip>
        </Dropdown>
    </Row>;
}

const initializeName = (name: string) =>
    name
        .split(" ")
        .map((a) => a[0])
        .join("");

function ProjectItem({ projectId }: { projectId: string }) {
    const route = useRoute();
    let project = useStreamDocumentById(collections.projects, projectId);
    let thumbnailUrl = useProjectThumbnailUrl(projectId);
    let name = project.status === 'loaded' ? project.value.name : 'Loading...';
    return <SidebarItem link={`/projects/${projectId}`} title={name} image={thumbnailUrl ?? undefined} selected={route === `/projects/${projectId}`}>
        {thumbnailUrl ? null : initializeName(name)}
    </SidebarItem>
}

function OrganizationItem({ organizationId }: { organizationId: string }) {
    const route = useRoute();
    let project = useStreamDocumentById(collections.organizations, organizationId);
    let name = project.status === 'loaded' ? project.value.name : 'Loading...';
    return <SidebarItem link={`/organizations/${organizationId}`} title={name} selected={route === `/organizations/${organizationId}`}>
        {initializeName(name)}
    </SidebarItem>
}

export function SplitMain({ children }: { children?: React.ReactNode }) {
    return <Row className='SplitMain'>{children}</Row>
}
export function SplitMainLeft({ children }: { children?: React.ReactNode }) {
    return <KeyboardColumn className='SplitMainLeft'>{children}</KeyboardColumn>
}
export function SplitMainRight({ children }: { children?: React.ReactNode }) {
    return <Column className='SplitMainRight'>{children}</Column>
}
