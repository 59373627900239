import './index.css';
import React, { useState } from 'react';
import { KeyboardColumn, KeyboardRow, Panel, Row } from '../Layout';
import { collections, useAuth, useStreamDocumentById } from '../../FirebaseHooks';
import { Dropdown, Tooltip } from '../Dropdown';
import { LauncherContext } from '../../App';
import { getAuth, signOut, updateProfile, User } from 'firebase/auth';
import { Button } from '../Button';
import { DialogScreen, ScreenContainer, ScreenType } from '../Screen';
import { Dialog, FilePrompt, Prompt } from '../Dialog';
import { StringEditor } from '../Editor';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { UserName, UserProfileImage } from '../User';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getApp } from 'firebase/app';
import { DownloadLauncherDialog } from '../DownloadLauncher';
import { Menu } from '../Menu';

const storage = getStorage(getApp());

export function FirstTimeSetupUserProfile({ auth }: { auth: User }) {
    let profile = useStreamDocumentById(collections.profiles, auth.uid);
    if (profile.status === 'error' && profile.error === 'no-such-document') {
        return <ScreenContainer screen={<FirstTimeSetupUserProfileScreen user={auth} />} />
    } else {
        return null;
    }
}
function FirstTimeSetupUserProfileScreen({ user }: { user: User }) {
    let [displayName, setDisplayName] = React.useState(user.displayName ?? '');
    return <DialogScreen>
        <Dialog>
            <KeyboardColumn style={{ alignItems: 'start' }}>
                <h2>Finish setting up your profile</h2>
                <KeyboardRow>
                    Name: <StringEditor value={displayName} onChange={setDisplayName} />
                </KeyboardRow>
                <Button primary={true} onClick={() => {
                    setDoc(doc(collections.profiles, user.uid), {
                        displayName,
                    });
                }}>Save</Button>
            </KeyboardColumn>
        </Dialog>
    </DialogScreen>
}

export function LoginInfo({ user }: { user: User }) {
    let [screen, setScreen] = React.useState<ScreenType>(null);
    let dropdown = () => <Menu>
        <Button onClick={() => {
            setScreen(<Prompt
                setScreen={setScreen}
                title="New name"
                // defaultValue={pack.name}
                onOk={async displayName => {
                    await updateDoc(doc(collections.profiles, user.uid), { displayName });
                    await updateProfile(user!, { displayName });
                }}
            />);
        }} >Rename</Button>
        <Button onClick={() => {
            setScreen(<FilePrompt
                setScreen={setScreen}
                title="New profile image"
                accept='image/*'
                onOk={async file => {
                    const ending = file.type.split('/')[1];
                    const path = `users/${user.uid}/profile.${ending}`;
                    const fileRef = ref(storage, path);
                    await uploadBytes(fileRef, file);
                    await updateDoc(doc(collections.profiles, user.uid), { imagePath: path });
                }}
            />);
        }} >Change profile image</Button>
        <Button flat={true} onClick={e => { e.preventDefault(); signOut(getAuth()) }}>Logout</Button>
    </Menu>;
    return <Row className='LoginInfo'>
        <ScreenContainer screen={screen} />
        <Dropdown dropdown={dropdown}>
            <UserProfileImage userId={user.uid} large={true} />
        </Dropdown>
        <LauncherIcon />
    </Row>
}

function LauncherIcon() {
    let [screen, setScreen] = useState<ScreenType>(null);
    let launcher = React.useContext(LauncherContext);
    let launcherTooltip = () => launcher.state === 'not-running' ?
        <div>Launcher not running, click to download</div> :
        launcher.state === 'running' ? <div>Launcher running ok</div> :
            <div>Loading</div>;
    let launcherColor = launcher.state === 'not-running' ? 'red' : launcher.state === 'running' ? '#1dca1d' : 'grey';
    return <Tooltip tooltip={launcherTooltip} className="LauncherIcon">
        <ScreenContainer screen={screen} />
        <Button onClick={() => setScreen(<DownloadLauncherDialog setScreen={setScreen} />)}>
            <i className="fa-solid fa-satellite-dish" style={{ color: launcherColor }} />
        </Button>
    </Tooltip>;
}
