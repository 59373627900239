import './index.css';
import { DbAssetPack, DbId, DbPipelineJob, isAssetPackOwner, stringifyOwnerId } from "dims-shared";
import { Button } from "../Button";
import { KeyboardColumn, KeyboardRow, Page, Panel, Row } from "../Layout";
import { Link } from "../Routing";
import { UserName } from "../User";
import { addDoc, collection, deleteDoc, doc, DocumentReference, getDocs, getFirestore, Timestamp, updateDoc, where } from 'firebase/firestore';
import { useState, useMemo, useContext, useEffect } from 'react';
import { useAuth, useStreamQuery, queryT, collections, whereT, QueryT, useStreamDocumentById, useStreamDocument, useMemberships, orderByT } from '../../FirebaseHooks';
import { Loading } from '../Loading';
import { DialogScreen, ScreenContainer, ScreenType, SetScreen } from '../Screen';
import { OwnerName } from '../Relationships';
import { confirmClick, ConfirmingButton, PromptingButton } from '../Dialog/Buttons';
import React from 'react';
import { getApp } from 'firebase/app';
import { Dialog, Prompt } from '../Dialog';
import { deleteObject, FirebaseStorage, getBlob, getBytes, getDownloadURL, getMetadata, getStorage, listAll, ref, StorageReference, uploadString } from 'firebase/storage';
import { deleteRecursive, StorageBrowser } from '../StorageBrowser';
import * as semver from 'semver';
import { Dropdown, Tooltip } from '../Dropdown';
import { Menu } from '../Menu';
import { AnyEditor, CheckboxEditor, EditorType } from '../Editor';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { formatDistance, formatRelative } from 'date-fns';
import { DateFormatDistance } from '../Misc';
import { Loadable } from '../../Util';
import { EditPipelineScreen } from './Pipeline';
import { Tab, Tabs } from '../Tabs';

const db = getFirestore(getApp());
const assetPacksStorage = getStorage(getApp(), "gs://dims-assets");
const assetCratesStorage = getStorage(getApp(), "gs://dims-asset-crates");
const functions = getFunctions(getApp());

export function AssetPackList({ baseQuery, ownerId }: { baseQuery: QueryT<DbAssetPack>, ownerId: string }) {
    // let [tags, setTags] = useState<{ [tag: string]: boolean }>({});

    let packs = useStreamQuery(useMemo(() => {
        let q = queryT(baseQuery, whereT('deleted', '==', false), orderByT('name'));
        // for (const tag of Object.keys(tags)) {
        //     if (tags[tag]) {
        //         q = queryT(q, where(`tags.${tag}`, '==', true));
        //     }
        // }
        return q;
    }, [baseQuery]));

    let [selected, setSelected] = useState<null | string>(null);


    return <Loading values={{ packs }}>{({ packs }) => {
        let selectedPack = packs.find(p => p.id === selected);
        return <KeyboardRow style={{ flex: 1, overflow: 'hidden' }}>
            <KeyboardColumn className='AssetPackListLeft' >
                {packs.map(pack => <AssetPackItem
                    key={pack.id}
                    pack={pack}
                    selected={selected}
                    setSelected={setSelected} />)}
                <PromptingButton
                    flat={true}
                    onOk={async name => {
                        let assetDoc = await addDoc(collections.assetPacks, {
                            name,
                            created: Timestamp.now(),
                            ownerId,
                            public: false,
                            versions: [],
                            deleted: false,
                        });
                        setSelected(assetDoc.id);
                    }}
                ><i className="fa-solid fa-circle-plus"></i> New asset pack</PromptingButton>
            </KeyboardColumn>
            {selectedPack && <AssetPackDetails pack={selectedPack} key={selectedPack.id} />}
        </KeyboardRow>;
    }}</Loading>
}
function AssetPackItem({ pack, selected, setSelected }: { pack: DbAssetPack & DbId, selected: string | null, setSelected: (id: string) => void }) {
    const deletePack = async () => {
        await deleteRecursive(ref(assetPacksStorage, `packs/${pack.id}/`));
        await deleteDoc(doc(collections.assetPacks, pack.id));
    };
    const setPublic = async (public_: boolean) => {
        await updateDoc(doc(collections.assetPacks, pack.id), { public: public_ });
        let assets = await getDocs(queryT(collections.assets, whereT('assetPackId', '==', pack.id)));
        await Promise.all(assets.docs.map(doc => updateDoc(doc.ref, { public: public_ })));
    };
    const config = (setScreen: SetScreen) => <Menu>
        <PromptingButton title='New name' defaultValue={pack.name} onOk={async name => {
            await updateDoc(doc(collections.assetPacks, pack.id), { name });
        }} >Rename</PromptingButton>
        <Button onClick={confirmClick(setScreen, deletePack)}>Delete</Button>
        {pack.public ?
            <Button onClick={confirmClick(setScreen, async () => await setPublic(false))}>Make private</Button> :
            <Button onClick={confirmClick(setScreen, async () => await setPublic(true))}>Make public</Button>}
    </Menu>;
    const publicIcon = pack.public ? <i className="fa-solid fa-earth-americas"></i> : <i className="fa-solid fa-lock"></i>;
    return <KeyboardRow className='AssetPackItem'>
        <Button
            key={pack.id}
            flat={true}
            style={{ textAlign: 'left', flex: 1 }}
            toggled={pack.id === selected}
            onClick={() => setSelected(pack.id)}
        >{publicIcon} {pack.name}</Button>
        <Dropdown className='AssetPackItemConfig' dropdown={config} flat={true}><i className="fa-solid fa-gear"></i></Dropdown>
    </KeyboardRow>
}

function AssetPackJobs({ packId, history = false }: { packId: string, history?: boolean }) {
    let query = useMemo(() => {
        if (history) {
            return queryT(collections.pipelineJobs,
                whereT('assetPackId', '==', packId),
                orderByT('created', 'desc'));
        } else {
            return queryT(collections.pipelineJobs,
                whereT('assetPackId', '==', packId),
                whereT('state', '!=', 'completed'));
        }
    }, [packId, history]);
    return <PipelineJobs query={query} />;

}
export function PipelineJobs({ query, showAsset = false }: { query: QueryT<DbPipelineJob>, showAsset?: boolean }) {
    let jobs = useStreamQuery(query);
    return <Loading values={{ jobs }}>{({ jobs }) => {
        if (jobs.length === 0) {
            return null;
        }
        return <div className='PipelineJobs'>
            <div className='PipelineJobsHeader' style={{ gridArea: 'auto / asset / auto / auto' }}>Asset</div>
            <div className='PipelineJobsHeader' style={{ gridArea: 'auto / jobStatus / auto / auto' }}>Job status</div>
            <div className='PipelineJobsHeader' style={{ gridArea: 'auto / created / auto / auto' }}>Created</div>
            <div className='PipelineJobsHeader' style={{ gridArea: 'auto / duration / auto / auto' }}>Duration</div>
            <div className='PipelineJobsHeader' style={{ gridArea: 'auto / status / auto / auto' }}>Status</div>
            <div className='PipelineJobsHeader' style={{ gridArea: 'auto / actions / auto / auto' }} />
            {jobs.map(job => <PipelineJob job={job} key={job.id} showAsset={showAsset} />)}
        </div>
    }}</Loading>
}
function PipelineJob({ job, showAsset }: { job: DbPipelineJob & DbId, showAsset: boolean }) {
    let duration = null;
    if (job.state === 'queued') {
        duration = <>...</>;
    } else if (job.state === 'running') {
        duration = job.started ? <DateFormatDistance date={job.started.toDate()} /> : <>...</>;
    } else {
        if (job.started && job.completed) {
            duration = <>{formatDistance(job.started.toDate(), job.completed.toDate())}</>
        } else {
            duration = <>...</>;
        }
    }
    const errors = job.errors && job.errors.length > 0 ? <>{job.errors.map(error => <Tooltip className='PipelineJobError' tooltip={() => <div>{error}</div>}>{error}</Tooltip>)}</> : null;
    return <>
        <div style={{ gridArea: 'auto / asset / auto / auto' }}><AssetPackName id={job.assetPackId} /></div>
        <div style={{ gridArea: 'auto / jobStatus / auto / auto' }}>{job.state}</div>
        <div style={{ gridArea: 'auto / created / auto / auto' }}><DateFormatDistance date={job.created.toDate()} /> ago</div>
        <div style={{ gridArea: 'auto / duration / auto / auto' }}>{duration}</div>
        <div style={{ gridArea: 'auto / status / auto / auto' }} title={job.status}>{job.status}</div>
        <div style={{ gridArea: 'auto / actions / auto / auto' }}>{job.state === 'queued' && <div><Button onClick={async () => deleteDoc(doc(collections.pipelineJobs, job.id))}>Cancel</Button></div>}</div>
        {errors}</>;
}
function AssetPackName({ id }: { id: string }) {
    let pack = useStreamDocument(useMemo(() => doc(collections.assetPacks, id), [id]));
    return <Loading values={{ pack }}>{({ pack }) => <>{pack.name}</>}</Loading>
}
function AssetPackJobsDialog({ packId, setScreen }: { packId: string, setScreen: SetScreen }) {
    return <DialogScreen onClose={() => setScreen(null)}>
        <Dialog>
            <AssetPackJobs packId={packId} history={true} />
        </Dialog>
    </DialogScreen>
}

function AssetPackDetails({ pack }: { pack: DbAssetPack & DbId }) {
    let storageRef = React.useMemo(() => ref(assetPacksStorage, `packs/${pack.id}`), [pack.id]);
    let contentStorageRef = React.useMemo(() => ref(storageRef, `content`), [storageRef]);
    let [isRemoving, setIsRemoving] = React.useState(false);
    let [screen, setScreen] = React.useState<ScreenType>(null);
    let auth = useAuth();
    let memberships = useMemberships(auth?.uid ?? '');
    const packRef = doc(collections.assetPacks, pack.id);
    let isOwner = isAssetPackOwner(pack.ownerId, auth?.uid ?? '', memberships);
    let [selectedPath, setSelectedPath] = useState(contentStorageRef.fullPath);
    let [filesRefreshToken, setFilesRefreshToken] = useState({});
    let version = pack.versions[pack.versions.length - 1];

    if (isRemoving) {
        return <Row>Deleting files...</Row>;
    }

    let ownerControlls = isOwner ? <KeyboardRow style={{ alignItems: 'center' }}>
        {/* <Button toggled={pack.public} onClick={() => updateDoc(packRef, { public: !pack.public })}>Public</Button> */}
        {version ? `v${version}` : <i>Not released yet</i>}
        <Button onClick={() => setScreen(<PublishAssetsScreen pack={pack} setScreen={setScreen} />)}>Publish</Button>
        <Button onClick={() => setScreen(<EditPipelineScreen
            selectedPath={selectedPath}
            setScreen={screen => { setFilesRefreshToken({}); setScreen(screen); }} />)}>Edit pipeline</Button>
        <Button onClick={() => setScreen(<AssetPackAssetsDialog packId={pack.id} setScreen={setScreen} />)} flat={true}>Assets</Button>
        <Button onClick={() => setScreen(<AssetPackJobsDialog packId={pack.id} setScreen={setScreen} />)} flat={true}>Jobs</Button>
        <small>{pack.id}</small>
    </KeyboardRow> : null;
    return <KeyboardColumn>
        <ScreenContainer screen={screen} />
        {ownerControlls}
        <AssetPackJobs packId={pack.id} />
        <StorageBrowser storageRef={contentStorageRef} storage={assetPacksStorage} selectedPath={selectedPath} setSelectedPath={setSelectedPath} refreshFilesToken={filesRefreshToken} />
    </KeyboardColumn>
}

function AssetPackAssetsDialog({ packId, setScreen }: { packId: string, setScreen: SetScreen }) {

    return <DialogScreen onClose={() => setScreen(null)}>
        <Dialog fullscreen={true}>
            <KeyboardColumn style={{ alignItems: 'start' }}>
                <Button onClick={() => setScreen(null)}>Close</Button>
                <Tabs>
                    <Tab label="Assets"><AssetList packId={packId} /></Tab>
                    <Tab label="Files"><AssetPackCrateFiles packId={packId} /></Tab>
                </Tabs>

            </KeyboardColumn>
        </Dialog>
    </DialogScreen>
}
function AssetPackCrateFiles({ packId }: { packId: string }) {
    let [selectedPath, setSelectedPath] = useState(`crates/${packId}`);
    return <StorageBrowser
        storage={assetCratesStorage}
        storageRef={useMemo(() => ref(assetCratesStorage, `crates/${packId}`), [packId])}
        selectedPath={selectedPath}
        setSelectedPath={setSelectedPath}
        refreshFilesToken={() => { }}
        editable={false}
    />
}
function AssetList({ packId }: { packId: string }) {
    let assets = useStreamQuery(useMemo(() => queryT(collections.assets, whereT('assetPackId', '==', packId)), [packId]));
    return <Loading values={{ assets }}>{({ assets }) => <div style={{ overflowY: 'scroll', flex: 1 }}><table>
        <tbody>
            <tr><th>Type</th><th></th><th>Name</th><th>Version</th><th>Created</th><th>Id</th><th>Source</th></tr>
            {assets.sort((a, b) => (a.hidden === b.hidden) ? 0 : a.hidden ? 1 : -1)
                .map(({ id, type, hidden, isCollection, previewUrl, name, version, created, source, content, collection }) => (
                    <tr key={id} style={{ opacity: hidden ? 0.5 : 1 }}>
                        <td>{type}</td>
                        <td>{previewUrl ?
                            <Tooltip tooltip={() => <img src={previewUrl} alt="Full" />}><img src={previewUrl} width={20} alt="Preview" /></Tooltip> : null}
                            {isCollection ? <i className="fa-solid fa-box-open"></i> : null}
                        </td>
                        <td><Tooltip tooltip={() => <KeyboardColumn>
                            {id}
                        </KeyboardColumn>}>{name}</Tooltip></td>
                        <td>{version}</td>
                        <td>{created ? formatRelative(created.toDate(), new Date()) : ''}</td>
                        <td><small>{id}</small></td>
                        <td><small>{source}</small></td>
                        <td><Button onClick={() => navigator.clipboard.writeText(content ? content : JSON.stringify(collection))}>Copy content</Button></td>
                    </tr>))}
        </tbody>
    </table></div>}</Loading>;
}

function PublishAssetsScreen({ pack, setScreen }: { pack: DbAssetPack & DbId, setScreen: SetScreen }) {
    let [release, setRelease] = React.useState<'major' | 'minor' | 'patch'>('patch');
    let newVersion = pack.versions.length > 0 ?
        semver.inc(pack.versions[pack.versions.length - 1] ?? '0.0.0', release)! :
        '1.0.0';

    let [progress, setProgress] = useState<null | string>(null);

    let publish = async () => {
        setProgress("Starting pipeline...");
        await updateDoc(doc(collections.assetPacks, pack.id), {
            versions: [...pack.versions, newVersion]
        });
        addDoc(collections.pipelineJobs, {
            created: Timestamp.now(),
            ownerId: pack.ownerId,
            assetPackId: pack.id,
            state: 'queued',
            status: 'Queued',
            statusTime: Timestamp.now(),
        });
        setScreen(null);
    };

    if (progress) {
        return <DialogScreen>
            <Dialog><KeyboardColumn>
                {progress}
            </KeyboardColumn></Dialog>
        </DialogScreen>;
    }

    return <DialogScreen onClose={() => setScreen(null)}>
        <Dialog><KeyboardColumn>
            {pack.versions.length > 0 && <KeyboardColumn>
                <label><input type="radio" checked={release === 'major'} onChange={() => setRelease('major')} />Major: Users will need to make changes to their world to use this new version</label>
                <label><input type="radio" checked={release === 'minor'} onChange={() => setRelease('minor')} />Minor: There are visual changes in this release, but user should be able to upgrade without modifying their world</label>
                <label><input type="radio" checked={release === 'patch'} onChange={() => setRelease('patch')} />Patch: There are no visible changes in this release; the changes are all invisible or internal</label>
            </KeyboardColumn>}
            <KeyboardRow>New version: {newVersion}</KeyboardRow>
            <KeyboardRow>
                <Button primary={true} onClick={publish}>Publish</Button>
                <Button onClick={() => setScreen(null)}>Cancel</Button>
            </KeyboardRow>
        </KeyboardColumn></Dialog>
    </DialogScreen>;
}

async function listAllRecursive(ref: StorageReference) {
    let files = await listAll(ref);
    let items = files.items;
    for (const dir of files.prefixes) {
        items = [...items, ...(await listAllRecursive(dir))];
    }
    return items;
}
