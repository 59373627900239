import React from 'react';
import { Loadable } from './Util';


export async function fetchLoadable<T, R>(url: string, init?: RequestInit): Promise<Loadable<T>> {
    try {
        const resp = await fetch(url, init);
        if (!resp.ok || resp.status !== 200) {
            return { status: 'error', error: await resp.json() }
        } else {
            return { status: 'loaded', value: await resp.json() };
        }
    } catch (err) {
        return { status: 'error', error: err };
    }
}

export function useFetch<T>(url: string, options?: RequestInit & { run?: boolean }) {
    const [result, setResult] = React.useState<Loadable<T>>({ status: 'loading' });
    React.useEffect(() => {
        (async () => {
            if (!options || options.run !== false) {
                setResult(await fetchLoadable(url, options));
            }
        })();
    }, [url, options]);
    return result;
}

export function useInterval(callback: () => void, delay: number | undefined) {
    const savedCallback = React.useRef<() => void>();
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    React.useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== undefined) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        } else {
            return undefined;
        }
    }, [delay]);
}

// export function useMemoAsync<T>(cb: () => Promise<T>, dependencies?: any[]) {
//     const [result, setResult] = React.useState<Loadable<T>>({ status: 'loading' });
//     React.useEffect(() => {
//         cb().then(v => setResult({ status: 'loaded', value: v }));
//     }, dependencies);
//     return result;
// }
