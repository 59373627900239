import React from 'react';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collections, queryT, useAuth, useStreamDocumentById, useStreamQuery, whereT } from '../../FirebaseHooks';
import { collection, getFirestore, doc, runTransaction, getDoc, updateDoc } from 'firebase/firestore';
import { Db, DbId, DbInstance, DbProject, full, partial, ReqStartGameInstance, RespStartGameInstance, PlayConfigMatchmaking, DbMatchmakingQueue, base62uuid } from 'dims-shared';
import { KeyboardRow, Row } from '../../Components/Layout';
import { LauncherContext } from '../../App';
import { Button } from '../../Components/Button';
import { User } from 'firebase/auth';
import { arrayRemoveIndex } from '../../Util';
import { launchDimsApp } from './Instances';

const functions = getFunctions(getApp());
const db = getFirestore(getApp());
const startGameInstance = httpsCallable<ReqStartGameInstance, RespStartGameInstance>(functions, 'startGameInstance');

export function MatchmakingPlay({ project, config }: { project: DbProject & DbId, config: PlayConfigMatchmaking }) {
    let launcherRunning = React.useContext(LauncherContext);
    let auth = useAuth();
    let queues = useStreamQuery(React.useMemo(() => queryT(collections.matchmakingQueues,
        whereT('projectId', '==', project.id),
        whereT('starting', '==', false)),
        [project.id]));
    let [activeQueue, setActiveQueue] = React.useState<null | string>(null);

    if (queues.status !== 'loaded') {
        return <Row>Loading...</Row>;
    }
    return <KeyboardRow>
        {activeQueue && auth ? <KeyboardRow>
            <Queuing queueId={activeQueue} project={project} config={config} auth={auth} onLeaveQueue={() => setActiveQueue(null)} />
        </KeyboardRow> : <KeyboardRow>
            <Button primary={true} disabled={launcherRunning.state !== 'running' || !!activeQueue} onClick={async () => {
                if (queues.status === 'loaded' && auth) {
                    let res = await joinQueue(project, config, auth, queues.value);
                    if (res.status === 'ok') {
                        setActiveQueue(res.id);
                    } else {
                        window.alert("Failed to join queue");
                    }
                }
            }}><i className="fa-solid fa-play" /> Play</Button>
            {queues.value.length} queues active
        </KeyboardRow>}
    </KeyboardRow>;
}
async function joinQueue(project: DbProject & DbId, config: PlayConfigMatchmaking, auth: User, queues: Array<DbMatchmakingQueue & DbId>) {
    queues = [...queues];
    for (let i = 0; i < 5; i++) {
        let queueIndex = queues.findIndex(q => q.users.length < config.maxPlayers);
        const selectedQueue = queueIndex >= 0 ? queues.splice(queueIndex, 1)[0] : null;
        let res = await runTransaction(db, async t => {
            if (selectedQueue) {
                let queueDoc = await t.get(doc(collections.matchmakingQueues, selectedQueue.id));
                let queue = queueDoc.data();
                if (!queue || queue.instance || queue.users.length >= config.maxPlayers) {
                    return { status: 'full' as 'full' };
                } else {
                    t.update(queueDoc.ref, { users: [...queue.users, auth!.uid] });
                    return { status: 'ok' as 'ok', id: queueDoc.id };
                }
            } else {
                let id = base62uuid();
                t.set(doc(collections.matchmakingQueues, id), {
                    projectId: project.id,
                    users: [auth.uid],
                    starting: false,
                });
                return { status: 'ok' as 'ok', id };
            }
        });
        if (res.status === 'ok') {
            return res;
        }
    }
    return { status: 'failed' as 'failed' };
}
function Queuing({ queueId, project, config, auth, onLeaveQueue }: { queueId: string, project: DbProject & DbId, config: PlayConfigMatchmaking, auth: User, onLeaveQueue: () => void }) {
    let queue = useStreamDocumentById(collections.matchmakingQueues, queueId);
    if (queue.status !== 'loaded') {
        return <Row>Loading...</Row>;
    } else if (queue.value.instance) {
        return <QueuingLaunching instanceId={queue.value.instance} auth={auth} onLaunched={onLeaveQueue} />;
    } else {
        return <QueuingWaiting activeQueue={queue.value} project={project} config={config} auth={auth} onLeaveQueue={onLeaveQueue} />;
    }
}
const MatchesLaunched: { [key: string]: boolean } = {};
function QueuingLaunching({ instanceId, auth, onLaunched }: { instanceId: string, auth: User, onLaunched: () => void }) {
    let launcherRunning = React.useContext(LauncherContext);
    React.useEffect(() => {
        if (MatchesLaunched[instanceId]) {
            return;
        }
        MatchesLaunched[instanceId] = true;
        (async () => {
            console.log('Launching app for instance', instanceId);
            let instance = (await getDoc(doc(collections.instances, instanceId))).data()!; // TODO: Don't assume instance exists
            await launchDimsApp({
                launcherPort: launcherRunning.state === 'running' ? launcherRunning.port : 0,
                port: instance.port,
                host: instance.host,
                version: instance.appVersion,
                userId: auth.uid,
            });
            await new Promise(r => setTimeout(r, 5000));
            onLaunched();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <Row>
        Launching
    </Row>
}
const ServersLaunched: { [key: string]: boolean } = {};
function QueuingWaiting({ activeQueue, project, config, auth, onLeaveQueue }: { activeQueue: DbMatchmakingQueue & DbId, project: DbProject & DbId, config: PlayConfigMatchmaking, auth: User, onLeaveQueue: () => void }) {
    React.useEffect(() => {
        if (!activeQueue.starting && activeQueue.users.length >= config.minPlayers) {
            if (ServersLaunched[activeQueue.id]) {
                return;
            }
            ServersLaunched[activeQueue.id] = true;
            // If we haven't started an instance, but it's time to start one, then all client "bid" on starting it.
            // Only one can win since it's a transaction
            (async () => {
                let queueRef = doc(collections.matchmakingQueues, activeQueue.id);
                let res = await runTransaction(db, async (t): Promise<'ok' | 'fail'> => {
                    let queueDoc = await t.get(queueRef);
                    let queue = queueDoc.data();
                    if (!queue || queue.starting) {
                        return 'fail';
                    }
                    t.update(queueDoc.ref, { starting: true });
                    return 'ok';
                });
                if (res === 'ok') {
                    console.log('Starting game server');
                    // We've "won" the bid here, so no need for transactions or anything any more
                    let res = (await startGameInstance({ projectId: project.id, mode: 'Play' })).data;
                    if (res.status === 'ok') {
                        updateDoc(queueRef, { instance: res.instanceId });
                    }
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeQueue]);
    if (activeQueue.starting) {
        return <Row>Game server starting...</Row>;
    }
    return <Row>
        <Button onClick={async () => {
            onLeaveQueue();
            runTransaction(db, async t => {
                let queueDoc = await t.get(doc(collections.matchmakingQueues, activeQueue.id));
                let queue = queueDoc.data()!;
                t.update(queueDoc.ref, { users: arrayRemoveIndex(queue.users, queue.users.findIndex(u => u === auth.uid)) });
            });

        }}>Leave queue</Button>
        {activeQueue.users.length} / {config.minPlayers}
    </Row>
}
