import React from 'react';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collections, queryT, useAuth, useStreamQuery, whereT } from '../../FirebaseHooks';
import { getFirestore } from 'firebase/firestore';
import { DbId, DbProject, ReqStartGameInstance, RespStartGameInstance } from 'dims-shared';
import { KeyboardColumn, Panel, Row } from '../../Components/Layout';
import { LauncherContext } from '../../App';
import { Tooltip } from '../../Components/Dropdown';
import { DialogScreen, ScreenContainer, ScreenType, SetScreen } from '../../Components/Screen';
import { Dialog } from '../../Components/Dialog';
import { Button } from '../../Components/Button';
import { MatchmakingPlay } from './Matchmaking';
import { DownloadLauncherDialog } from '../../Components/DownloadLauncher';

const functions = getFunctions(getApp());
const db = getFirestore(getApp());
const startGameInstance = httpsCallable<ReqStartGameInstance, RespStartGameInstance>(functions, 'startGameInstance');

export async function launchDimsApp({ launcherPort, host, port, version, userId }: { launcherPort: number, host: string, port: number, version: string, userId: string }) {
    await fetch(`https://dims-launcher.com:${launcherPort}/launch`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            version,
            port,
            host,
            user_id: userId,
        })
    });
}

export function LaunchEdit({ projectId, appVersion }: { projectId: string, appVersion: string }) {

    let instances = useStreamQuery(React.useMemo(() => projectId ? queryT(collections.instances,
        whereT('projectId', '==', projectId),
        whereT('state', 'in', ['running', 'starting']),
        whereT('mode', '==', 'Edit')) :
        null,
        [projectId]));

    let [state, setState] = React.useState<'start' | 'starting-server' | 'error' | 'launching-app'>('start');
    let launcherRunning = React.useContext(LauncherContext);
    let auth = useAuth();
    let [screen, setScreen] = React.useState<ScreenType>(null);
    let disabled = launcherRunning.state === 'loading' || instances.status !== 'loaded' || state !== 'start';
    let tooltip = state === 'error' ? <div>Error launching server</div> :
        state === 'launching-app' ? <div>Launching app...</div> :
            state === 'starting-server' ? <div>Starting server...</div> :
                null;
    return <Tooltip tooltip={() => tooltip}><Button disabled={disabled} primary={true} onClick={async e => {
        if (launcherRunning.state === 'not-running') {
            setScreen(<DownloadLauncherDialog setScreen={setScreen} />);
            return;
        }
        if (instances.status !== 'loaded') {
            return;
        }
        if (instances.value.length > 0) {
            setState('launching-app');
            await launchDimsApp({
                launcherPort: launcherRunning.state === 'running' ? launcherRunning.port : 0,
                host: instances.value[0].host,
                port: instances.value[0].port,
                version: appVersion,
                userId: auth?.uid ?? "",
            });
            await new Promise(resolve => setTimeout(resolve, 3000));
            setState('start');
        } else {
            setState('starting-server');
            let res = (await startGameInstance({ projectId, mode: 'Edit' })).data;
            if (res.status === 'ok') {
                setState('launching-app');
                await launchDimsApp({
                    launcherPort: launcherRunning.state === 'running' ? launcherRunning.port : 0,
                    host: res.host,
                    port: res.port,
                    version: appVersion,
                    userId: auth?.uid ?? "",
                });
                await new Promise(resolve => setTimeout(resolve, 3000));
                setState('start');
            } else {
                setState('error');
                await new Promise(resolve => setTimeout(resolve, 3000));
                setState('start');
            }
        }
    }}>
        <ScreenContainer screen={screen} />
        <i className="fa-solid fa-hammer"></i>&nbsp;
        Build
    </Button></Tooltip>
}

export function EditLiveIndicator({ projectId }: { projectId: string }) {

    let instances = useStreamQuery(React.useMemo(() => queryT(collections.instances,
        whereT('projectId', '==', projectId),
        whereT('state', 'in', ['running', 'starting']),
        whereT('mode', '==', 'Edit')),
        [projectId]));

    return <div className='EditLiveIndicator'>
        {instances.status !== 'loaded' ? <i className="fa-regular fa-circle" /> :
            instances.value.length === 0 ? <i className="fa-regular fa-circle" /> :
                instances.value[0].state === 'starting' ? <i className="fa-solid fa-circle-half-stroke" /> :
                    instances.value[0].state === 'running' ? <i className="fa-solid fa-circle LivePulse" /> :
                        <i className="fa-solid fa-circle-divide" />}
    </div>
}

export function PlayPanel({ project }: { project: DbProject & DbId }) {
    const config = project.playConfig ?? {};
    return <Panel>
        {config.type === 'one-off' ? <OneOffPlay project={project} /> :
            config.type === 'matchmaking' ? <MatchmakingPlay project={project} config={config} /> :
                <Row>Not implemented yet</Row>}
    </Panel>
}

function OneOffPlay({ project }: { project: DbProject & DbId }) {
    let [state, setState] = React.useState<'start' | 'starting-server' | 'failed' | 'starting-app' | 'running'>('start');
    let launcherRunning = React.useContext(LauncherContext);
    let auth = useAuth();
    return <Button primary={true} disabled={state !== 'start'} onClick={async () => {
        setState('starting-server');
        let res = (await startGameInstance({ projectId: project.id, mode: 'Play' })).data;
        if (res.status === 'failed') {
            setState('failed');
            await new Promise(r => setTimeout(r, 5000));
            setState('start');
            return;
        }
        setState('starting-app');
        await launchDimsApp({
            launcherPort: launcherRunning.state === 'running' ? launcherRunning.port : 0,
            port: res.port,
            host: res.host,
            version: project.appVersion,
            userId: auth?.uid ?? "",
        });
        setState('start');
    }}><i className="fa-solid fa-play" /> Play</Button>;
}
