import './index.css';
import { Db, DbId, DbIssue, DbMessage } from "dims-shared";
import { addDoc, collection, CollectionReference, deleteDoc, doc, Timestamp, updateDoc } from "firebase/firestore";
import React, { useMemo, useState } from "react";
import { collections, queryT, useAuth, useStreamDocumentById, useStreamQuery } from "../../FirebaseHooks";
import { Button } from "../Button";
import { Chat } from "../Chat";
import { CustomPrompt, Dialog, Prompt } from "../Dialog";
import { StringEditor } from "../Editor";
import { KeyboardColumn, KeyboardRow, Panel, Row } from "../Layout";
import { DialogScreen, ScreenContainer, ScreenType, SetScreen } from "../Screen";
import { Loading } from '../Loading';

export function Issues({ collection }: { collection: CollectionReference<DbIssue> }) {
    let issues = useStreamQuery(useMemo(() => queryT(collection), [collection]));
    let [screen, setScreen] = useState<ScreenType>(null);
    return <Loading values={{ issues }}>{({ issues }) => <KeyboardColumn style={{ alignItems: 'start' }}>
        <ScreenContainer screen={screen} />
        <Button onClick={() => setScreen(<NewIssuePrompt setScreen={setScreen} collection={collection} />)}>New issue</Button>
        {issues.map(issue => <Issue key={issue.id} issue={issue} collection={collection} />)}
    </KeyboardColumn>}</Loading>
}

function Issue({ issue, collection }: { issue: DbIssue & DbId, collection: CollectionReference<DbIssue> }) {
    let [screen, setScreen] = useState<ScreenType>(null);
    return <KeyboardRow>
        <ScreenContainer screen={screen} />
        <Button onClick={() => setScreen(<IssueScreen issueId={issue.id} onClose={() => setScreen(null)} issuesCollection={collection} />)}>
            {issue.title}
        </Button>
    </KeyboardRow>
}

function IssueScreen({ issueId, onClose, issuesCollection }: { issueId: string, onClose: () => void, issuesCollection: CollectionReference<DbIssue> }) {
    let issue = useStreamDocumentById(issuesCollection, issueId);
    return <DialogScreen onClose={onClose}>
        <Dialog className="IssuesScreen">
            {issue.status !== 'loaded' ? <Row>Loading...</Row> : <IssueInner issue={issue.value} onClose={onClose} issuesCollection={issuesCollection} />}
        </Dialog>
    </DialogScreen>
}

function IssueInner({ issue, onClose, issuesCollection }: { issue: DbIssue & DbId, onClose: () => void, issuesCollection: CollectionReference<DbIssue> }) {
    let [screen, setScreen] = useState<ScreenType>(null);
    let chatCollection = useMemo(() => collection(doc(issuesCollection, issue.id), Db.messages) as CollectionReference<DbMessage>, [issue.id, issuesCollection]);
    return <KeyboardColumn style={{ overflow: 'hidden', flex: 1 }}>
        <ScreenContainer screen={screen} />
        <h2>{issue.title}</h2>
        <KeyboardRow>
            <Button onClick={() => {
                setScreen(<Prompt
                    setScreen={setScreen}
                    title="New title"
                    defaultValue={issue.title}
                    onOk={async title => {
                        updateDoc(doc(issuesCollection, issue.id), { title });
                    }}
                />);
            }} >Change title</Button>
            <Button onClick={async () => {
                await deleteDoc(doc(issuesCollection, issue.id));
                onClose();
            }}>Delete</Button>
        </KeyboardRow>
        <Chat collection={chatCollection} />
    </KeyboardColumn>
}

function NewIssuePrompt({ setScreen, collection }: { setScreen: SetScreen, collection: CollectionReference<DbIssue> }) {
    let auth = useAuth();
    return <CustomPrompt<DbIssue> setScreen={setScreen} title={"New issue"}
        editor={(value, onChange, working) => <KeyboardColumn>
            <KeyboardRow>Title: <StringEditor value={value.title} onChange={title => onChange({ ...value, title })} /></KeyboardRow>
        </KeyboardColumn>}
        defaultValue={{ created: Timestamp.now(), title: '', ownerId: auth?.uid ?? '' }}
        onOk={async (value) => { await addDoc(collection, value); }} />;
}
