import './index.css';
import React from "react";
import { Button } from "../Button";
import { KeyboardColumn, KeyboardRow } from "../Layout";

export function TabBar<T extends string>({ value, onChange, tabs }: { value: T, onChange: (value: T) => void, tabs: readonly T[] }) {
    return <KeyboardRow className='TabBar'>
        {tabs.map((tab, i) => <Button className='TabBarItem' key={i} flat={true} onClick={() => onChange(tab)} toggled={value === tab}>
            {tab}
        </Button>)}
    </KeyboardRow>
}

export function Tabs({ children, style }: { children: JSX.Element[], style?: React.CSSProperties }) {
    let [tab, setTab] = React.useState<string>(children[0].props.label);

    return <KeyboardColumn style={style}>
        <TabBar value={tab} onChange={setTab} tabs={children.map(a => a.props.label)} />
        {children.find(a => a.props.label === tab)?.props.children}
    </KeyboardColumn>
}

export function Tab(_: { label: String, children: JSX.Element[] | JSX.Element }) {
    return null;
}