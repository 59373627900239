import React from 'react';
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { KeyboardColumn, Page, Row } from "../../Components/Layout";
import { collections, orderByT, queryT, useStreamDocumentById, useStreamQuery, whereT } from '../../FirebaseHooks';
import { DbId, DbInstance } from 'dims-shared';
import { Link } from '../../Components/Routing';
import { formatDistance } from 'date-fns';
import { Button } from '../../Components/Button';
import { limit } from 'firebase/firestore';

const functions = getFunctions(getApp());
const triggerUpdateInstanceRunning = httpsCallable(functions, 'triggerUpdateInstanceRunning');

export function AdminInstances() {
    return <KeyboardColumn style={{ alignItems: 'start' }}>
        <button onClick={() => triggerUpdateInstanceRunning()}>Update instances running</button>
        <InstancesRunning />
    </KeyboardColumn>
}

function InstancesRunning() {
    let [runningOnly, setRunningOnly] = React.useState(true);
    let instances = useStreamQuery(React.useMemo(() => {
        let q = queryT(collections.instances, orderByT('created', 'desc'), limit(100));
        if (runningOnly) {
            return queryT(q, whereT('state', '==', 'running'));
        } else {
            return q;
        }
    }, [runningOnly]));
    if (instances.status !== 'loaded') {
        return <Row>Loading...</Row>;
    }
    return <div>
        <Row>
            <input type="checkbox" checked={runningOnly} onClick={() => setRunningOnly(!runningOnly)} />
            Running only
        </Row>
        <table>
            <tr><th>Project</th><th>Version</th><th>Host</th><th>Instance id</th><th>Created</th><th>Duration</th><th>Status</th><th /><th /></tr>
            {instances.value.map(instance => <RunningInstance key={instance.id} instance={instance} />)}
        </table></div>
}
function RunningInstance({ instance }: { instance: DbInstance & DbId }) {
    let project = useStreamDocumentById(collections.projects, instance.projectId);
    let projectName = project.status === 'error' ? (project.error === 'no-such-document' ? 'No such document' : 'Error') :
        project.status === 'loading' ? 'Loading...' :
            project.value.name;
    return <tr key={instance.id} style={{ opacity: instance.state === 'running' ? 1 : 0.5, color: instance.state === 'exited' && instance.exitCode !== 0 ? 'red' : undefined }}>
        <td><Link to={`/projects/${instance.projectId}`}>{projectName}</Link></td>
        <td>{instance.appVersion}</td>
        <td>{instance.host}:{instance.port}</td>
        <td>{instance.id}</td>
        <td>{instance.created.toDate().toISOString()} {formatDistance(new Date(), instance.created.toDate())} ago</td>
        <td>{formatDistance((instance.stopped ? instance.stopped.toDate() : new Date()), instance.created.toDate())}</td>
        <td>{instance.state === 'running' ? 'Running' : instance.exitCode}</td>
        <td><a href={`http://${instance.host}:${instance.port}/asset_timeline`}>Asset cache</a></td>
        <td><Button flat={true} onClick={() => navigator.clipboard.writeText(`puffin_viewer --url ${instance.host}:${instance.port + 1}`)}><i className="fa-solid fa-kiwi-bird"></i></Button></td>
    </tr>
}
