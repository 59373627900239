import './index.css';
import React, { useMemo } from 'react';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collections, queryT, useAuth, useStreamDocumentById, useStreamQuery, whereT } from '../../FirebaseHooks';
import { addDoc, collection, deleteDoc, getFirestore, doc, query, where, getDocs, updateDoc, setDoc, CollectionReference, Timestamp } from 'firebase/firestore';
import { Db, DbMember, DbProject, full, PlayConfig, PlayConfigTypes, partial, ReqCloneProjectFiles, stringifyMemberId, PlayConfigDescriptions, DbMessage, DbIssue, DbId, stringifyOwnerId } from 'dims-shared';
import { Column, KeyboardColumn, KeyboardRow, Panel, Row } from '../../Components/Layout';
import { RoutingHistory } from '../../Components/Routing';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { DimsVersionSelect } from './New';
import { Dropdown } from '../../Components/Dropdown';
import { DialogScreen, ScreenContainer, ScreenType } from '../../Components/Screen';
import { CustomPrompt, Dialog, Prompt } from '../../Components/Dialog';
import { Button } from '../../Components/Button';
import { EditorProps, ListEditor, NumberEditor, StringEditor } from '../../Components/Editor';
import { EditLiveIndicator, LaunchEdit, PlayPanel, } from './Instances';
import { Chat } from '../../Components/Chat';
import { Tab, Tabs } from '../../Components/Tabs';
import { Issues } from '../../Components/Issues';
import { NewReleaseDialog, ProjectReleases } from './Releases';
import { ConfirmingButton, EditableString } from '../../Components/Dialog/Buttons';
import { Loading } from '../../Components/Loading';
import { SplitMain, SplitMainLeft, SplitMainRight } from '../Main';
import { ECSComponents, ECSComponentsForOwner } from '../../Components/ECSComponents';
import { Menu } from '../../Components/Menu';
import { AssetPackList } from '../../Components/AssetDatabase';


const functions = getFunctions(getApp());
const db = getFirestore(getApp());
const projectsStorage = getStorage(getApp(), "gs://dims-projects");

const cloneProjectFiles = httpsCallable<ReqCloneProjectFiles, string>(functions, 'cloneProjectFiles');


export function PlayConfigEditor({ value, onChange }: EditorProps<PlayConfig>) {
    const typeSelect = <KeyboardColumn>
        <Row>How should games be played?</Row>
        {PlayConfigTypes.map(type => <KeyboardRow>
            <input type="radio" checked={value.type === type} onChange={() => onChange({ ...value, type } as PlayConfig)} />
            <b>{type}:</b>&nbsp;
            {PlayConfigDescriptions[type]}
        </KeyboardRow>)}
    </KeyboardColumn>;
    switch (value.type) {
        case 'one-off': return <Row>{typeSelect}</Row>;
        case 'official-realms': return <KeyboardColumn>
            {typeSelect}
            <Row>Player limit: <NumberEditor value={value.playerLimit} onChange={playerLimit => onChange({ ...value, playerLimit })} /></Row>
            <Column>
                Realms:
                <ListEditor
                    value={value.realms}
                    onChange={realms => onChange({ ...value, realms })}
                    itemEditor={(value, onChange) => <StringEditor value={value} onChange={onChange} />}
                    newItem={() => ''} />
            </Column>
        </KeyboardColumn>;

        case 'player-realms': return <KeyboardColumn>
            {typeSelect}
            <Row>Player limit: <NumberEditor value={value.playerLimit} onChange={playerLimit => onChange({ ...value, playerLimit })} /></Row>
        </KeyboardColumn>;

        case 'matchmaking': return <KeyboardColumn>
            {typeSelect}
            <Row>Min players per match: <NumberEditor value={value.minPlayers} onChange={minPlayers => onChange({ ...value, minPlayers })} /></Row>
            <Row>Max players per match: <NumberEditor value={value.maxPlayers} onChange={maxPlayers => onChange({ ...value, maxPlayers })} /></Row>
        </KeyboardColumn>;

        case 'custom-game': return <KeyboardColumn>
            {typeSelect}
            <Row>Min players per match: <NumberEditor value={value.minPlayers} onChange={minPlayers => onChange({ ...value, minPlayers })} /></Row>
            <Row>Max players per match: <NumberEditor value={value.maxPlayers} onChange={maxPlayers => onChange({ ...value, maxPlayers })} /></Row>
        </KeyboardColumn>;
        default: return <KeyboardColumn>{typeSelect} Unknown type</KeyboardColumn>;
    }
}

export function useProjectThumbnailUrl(projectId: string) {
    let [url, setUrl] = React.useState<string | null>(null);
    React.useEffect(() => {
        (async () => {
            try {
                setUrl(await getDownloadURL(ref(projectsStorage, `projects/${projectId}/thumbnail.png`)));
            } catch {
                setUrl(null);
            }
        })();
    }, [projectId]);
    return url;
}

export function ProjectMain({ projectId }: { projectId: string }) {
    let project = useStreamDocumentById(collections.projects, projectId);
    let auth = useAuth();
    let membership = useStreamDocumentById(collections.members, stringifyMemberId('project', projectId, auth?.uid ?? ''));
    let thumbnailUrl = useProjectThumbnailUrl(projectId);
    let [screen, setScreen] = React.useState<ScreenType>(null);
    let chatCollection = useMemo(() => collection(doc(collections.projects, projectId), Db.messages) as CollectionReference<DbMessage>, [projectId]);
    return <Loading values={{ project }}>{({ project }) => {

        let isSaved = membership.status === 'loaded';
        let isAdmin = membership.status === 'loaded' && membership.value.isAdmin;
        let moreDropdown = () => <Menu>
            <Button flat={true} onClick={() => navigator.clipboard.writeText(`https://playdims.com/projects/${projectId}`)}>Copy invite link</Button>
            {isAdmin && <KeyboardColumn>

                <Button flat={true} onClick={() => setScreen(<NewReleaseDialog onClose={() => setScreen(null)} project={project} />)}>
                    Publish new release
                </Button>
                <Button flat={true} onClick={async () => {
                    let url = await getDownloadURL(ref(projectsStorage, `projects/${projectId}/map.json`));
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${project.name}.dims`;
                    link.click();
                }} >Download</Button>
                <Button flat={true} onClick={async () => {
                    setScreen(<DialogScreen><Dialog>Uploading...</Dialog></DialogScreen>);
                    const input = document.createElement("input");
                    input.type = "file";
                    input.onchange = async (e: any) => {
                        if (e.target.files && e.target.files.length === 1) {
                            let s = ref(projectsStorage, `projects/${projectId}/map.json`);
                            await uploadBytes(s, e.target.files[0], {
                                contentType: 'application/octet-stream',
                            });
                            setScreen(null);
                        }
                    };
                    input.click();
                }} >Upload</Button>

                <Button flat={true} onClick={() => {
                    setScreen(<CustomPrompt
                        setScreen={setScreen}
                        title="Instancing config"
                        defaultValue={project.playConfig ?? {}}
                        editor={(value, onChange) => <PlayConfigEditor value={value} onChange={onChange} />}
                        onOk={async playConfig => {
                            updateDoc(doc(collections.projects, projectId), { playConfig });
                        }}
                    />);
                }} >Edit instancing config</Button>
                <Button flat={true} onClick={() => {
                    setScreen(<CustomPrompt
                        setScreen={setScreen}
                        title="Change app version"
                        editor={(value, onChange, working) => <DimsVersionSelect value={value} onChange={onChange} />}
                        defaultValue={project.appVersion}
                        onOk={async version => {
                            if (window.confirm(`Are you sure you want to change the app version to ${version}?\n\nTip: You can use "Clone" to make a backup of your world first`)) {
                                updateDoc(doc(collections.projects, projectId), { appVersion: version });
                            }
                        }}
                    />);
                }} >Change app version</Button>
                <ConfirmingButton flat={true} confirm='Are you sure? This will permanently delete the project' onClick={async () => {
                    await deleteProject(project);
                    RoutingHistory.push('/');
                }} >Delete</ConfirmingButton>
            </KeyboardColumn>
            }

        </Menu>;
        return <SplitMain>
            <SplitMainLeft>
                <ScreenContainer screen={screen} />
                <PlayPanel project={project} />
                <KeyboardRow>
                    <Row style={{ position: 'relative' }}>
                        {thumbnailUrl ? <img className='ProjectImage' src={thumbnailUrl} alt="Thumbnail" /> : <div className='ProjectImage' />}
                        <div style={{ position: 'absolute', left: 10, top: 10 }}>
                            <EditLiveIndicator projectId={projectId} />
                        </div>
                    </Row>
                    <KeyboardColumn style={{ alignItems: 'start' }}>
                        Project • {project.appVersion}
                        <KeyboardRow>
                            <h1><EditableString editable={isAdmin} title='New name' onOk={async name => updateDoc(doc(collections.projects, projectId), { name })} >
                                {project.name}
                            </EditableString></h1>
                        </KeyboardRow>
                        <EditableString editable={isAdmin} title='New description' placeholder='No description' onOk={async description => updateDoc(doc(collections.projects, projectId), { description })}>
                            {project.description}
                        </EditableString>
                        <KeyboardRow>
                            <LaunchEdit projectId={project.id} appVersion={project.appVersion} />
                            {isAdmin ? null :
                                isSaved ? <button onClick={async () => {
                                    await deleteDoc(doc(collections.members, stringifyMemberId('project', project.id, auth!.uid)));
                                }} >Saved</button> :
                                    <button onClick={async () => {
                                        await setDoc(doc(collections.members, stringifyMemberId('project', project.id, auth!.uid)), {
                                            type: 'project',
                                            itemId: project.id,
                                            userId: auth!.uid,
                                            isAdmin: false,
                                            created: Timestamp.now(),
                                            sidebarOrder: Timestamp.now()
                                        }, { merge: true });
                                    }} >Save</button>}
                            <button onClick={() => {
                                (async () => {
                                    setScreen(<Prompt
                                        setScreen={setScreen}
                                        title="New name"
                                        defaultValue={project.name + ' clone'}
                                        onOk={async name => {
                                            let id = await cloneProject(projectId, project, name, auth!.uid);
                                            RoutingHistory.push(`/projects/${id}`);
                                        }}
                                    />);
                                })()
                            }} >Clone</button>
                            <Dropdown dropdown={moreDropdown}>More</Dropdown>
                        </KeyboardRow>
                    </KeyboardColumn>
                </KeyboardRow>
                <ProjectTabs project={project} />

            </SplitMainLeft>
            <SplitMainRight>
                <Chat collection={chatCollection} />
            </SplitMainRight>
        </SplitMain>
    }}</Loading>;
}

function ProjectTabs({ project }: { project: DbProject & DbId }) {
    let issuesCollection = useMemo(
        () =>
            collection(
                doc(collections.projects, project.id),
                Db.issues
            ) as CollectionReference<DbIssue>,
        [project.id]
    );
    let assetsBaseQuery = useMemo(() => queryT(collections.assetPacks, whereT('ownerId', '==', stringifyOwnerId('prj', project.id))), [project.id]);

    return (
        <KeyboardColumn style={{ flex: 1, overflow: 'hidden' }}>
            <Tabs style={{ flex: 1, overflow: 'hidden' }}>
                <Tab label="Releases">
                    <ProjectReleases project={project} />
                </Tab>
                <Tab label="Issues">
                    <Issues collection={issuesCollection} />
                </Tab>
                <Tab label="Asset packs">
                    <AssetPackList
                        baseQuery={assetsBaseQuery}
                        ownerId={stringifyOwnerId('prj', project.id)}
                    />
                </Tab>
                <Tab label="Components">
                    <ECSComponentsForOwner ownerId={stringifyOwnerId('prj', project.id)} />
                </Tab>
            </Tabs>
        </KeyboardColumn>
    );
}

async function deleteProject(project: DbProject & DbId) {
    await deleteDoc(doc(collections.projects, project.id));
    await deleteDoc(doc(collections.namespaces, project.namespace));
    for (const member of (await getDocs(queryT(collections.members, whereT('itemId', '==', project.id)))).docs) {
        await deleteDoc(member.ref);
    }
}

async function cloneProject(id: string, project: DbProject, newName: string, userId: string) {
    project.name = newName;
    let proj = await addDoc(collections.projects, project);
    await cloneProjectFiles({ fromId: id, toId: proj.id });
    await setDoc(doc(collections.members, stringifyMemberId('project', proj.id, userId)), {
        type: 'project',
        itemId: proj.id,
        userId,
        isAdmin: true,
        created: Timestamp.now(),
        sidebarOrder: Date.now()
    });
    // await Promise.all((await getDocs(query(collection(db, Db.members), where('projectId', '==', id)))).docs.map(async member => {
    //     let mem = member.data() as DbMember;
    //     mem.projectId = proj.id;
    //     await setDoc(doc(collection(db, Db.members), stringifyMemberId(proj.id, mem.userId)), mem);
    // }));
    return proj.id;
}
