import React, { } from 'react';
import { arrayRemoveIndex, arrayReplaceIndex, ifLet, none, Option, some } from '../../Util';
import { Button } from '../Button';
import { Tooltip } from '../Dropdown';
import { KeyboardColumn, KeyboardRow } from '../Layout';
import { ECSEntityDataEditor } from './ECS';

export interface EditorProps<T> {
    value: T,
    onChange: (value: T) => void,
}

interface StringEditorProps extends EditorProps<string> {
    placeholder?: string
}

export function StringEditor({ value, onChange, placeholder }: StringEditorProps) {
    return <input type="text" value={value} onChange={e => onChange(e.target.value)} placeholder={placeholder} />
}

export function CheckboxEditor({ value, onChange }: EditorProps<boolean>) {
    return <input type="checkbox" checked={value} onChange={e => onChange(e.target.checked)} />
}

interface ParsingEditorProps<T> extends EditorProps<T> {
    parse: (value: string) => Option<T>,
    stringify: (value: T) => string,
    inputProps: React.InputHTMLAttributes<HTMLInputElement>
}

export function ParsingEditor<T>({ value, onChange, parse, stringify, inputProps }: ParsingEditorProps<T>) {
    let [editingValue, setEditingValue] = React.useState(stringify(value));
    let [editing, setEditing] = React.useState(false);
    return <input
        {...inputProps}
        value={editing ? editingValue : stringify(value)}
        onChange={e => setEditingValue(e.target.value)}
        onFocus={e => { setEditingValue(stringify(value)); setEditing(true); }}
        onBlur={e => { ifLet(parse(editingValue), value => onChange(value)); setEditing(false); }}
    />
}


export enum NumberEditorType {
    SignedInteger,
    UnsignedInteger,
    Float
}
export function NumberEditor({ value, onChange, type }: { value: number, onChange: (value: number) => void, type?: NumberEditorType }) {
    return <ParsingEditor
        value={value}
        onChange={onChange}
        parse={value => {
            let res;
            if (type === NumberEditorType.Float) {
                res = parseFloat(value);
            } else {
                res = parseInt(value, 10);
            }
            if (isNaN(res)) {
                return none();
            } else {
                if (type === NumberEditorType.UnsignedInteger && res < 0) {
                    return none();
                }

                return some(res);
            }
        }}
        stringify={value => '' + value}
        inputProps={{ type: 'number' }}
    />
}

interface ListEditorProps<T> extends EditorProps<T[]> {
    itemEditor: (value: T, onChange: (value: T) => void) => React.ReactElement,
    newItem: () => T,
}

export function ListEditor<T>({ value = [], onChange, itemEditor, newItem }: ListEditorProps<T>) {
    return <KeyboardColumn style={{ alignItems: 'start' }}>
        {value.map((item, i) => <KeyboardRow key={i}>
            <Button flat={true} onClick={e => onChange(arrayRemoveIndex(value, i))}>
                <i className="fa-solid fa-trash"></i>
            </Button>
            {itemEditor(item, item => onChange(arrayReplaceIndex(value, i, item)))}
        </KeyboardRow>)}
        <Button flat={true} onClick={() => onChange([...value, newItem()])}>
            <i className="fa-solid fa-circle-plus"></i>
        </Button>
    </KeyboardColumn>
}

export type StructValue = { [key: string]: any };
export type EditorBaseType = { type: 'float' | 'int' | 'string' | 'bool' };
export interface EditorField {
    type: EditorType,
    name?: string,
    description?: string,
}
export type EditorFields = { [key: string]: EditorField };
export type EditorType = EditorBaseType |
{ type: 'struct', fields: EditorFields } |
{ type: 'enum-struct', variants: EnumStructVariants } |
{ type: 'enum', variants: EnumVariants } |
{ type: 'list', itemType: EditorType, newItem: () => any } |
{ type: 'option', defaultValue: () => any, innerType: EditorType } |
{ type: 'entity-data' } |
{ type: 'custom', editor: (value: any, onChange: (value: any) => void) => React.ReactElement };

export function editorOption(defaultValue: () => any, innerType: EditorType): EditorType {
    return { type: 'option', defaultValue, innerType };
}

export function StructEditor({ value, onChange, fields }: { value: StructValue, onChange: (value: StructValue) => void, fields: EditorFields }) {
    return <KeyboardColumn>{Object.entries(fields).map(([key, field]) => {
        let name = field.name ?? key;
        return <KeyboardRow key={key}>
            {field.description ? <Tooltip tooltip={() => <span>{field.description}</span>}>{name}</Tooltip> : name}:&nbsp;
            <AnyEditor value={value[key]} onChange={v => onChange({ ...value, [key]: v })} type={field.type} />
        </KeyboardRow>
    })}</KeyboardColumn>
}

export interface EnumStructVariants {
    [variant: string]: {
        fields?: EditorFields,
        default?: () => any,
    }
}

export function EnumStructEditor({ value = {}, onChange, variants }: { value: StructValue, onChange: (value: StructValue) => void, variants: EnumStructVariants }) {
    let firstVariant = Object.entries(variants)[0];
    let currentVariant = value['type'] ?? firstVariant[0];
    return <KeyboardColumn>
        <select value={currentVariant} onChange={e => {
            let v = variants[e.target.value].default?.() ?? {};
            onChange({ type: e.target.value, ...v })
        }}>
            {Object.keys(variants).map(variant => <option key={variant} value={variant}>{variant}</option>)}
        </select>
        <StructEditor value={value} onChange={onChange} fields={variants[currentVariant].fields ?? {}} />
    </KeyboardColumn>
}

export interface EnumVariants {
    [variant: string]: {
        description?: string,
    }
}

export function EnumEditor({ value, onChange, variants }: { value: string, onChange: (value: string) => void, variants: EnumVariants }) {
    let firstVariant = Object.entries(variants)[0];
    let currentVariant = value ?? firstVariant[0];
    return <KeyboardColumn>
        <select value={currentVariant} onChange={e => onChange(e.target.value)}>
            {Object.keys(variants).map(variant => <option key={variant} value={variant}>{variant} {variants[variant].description}</option>)}
        </select>
    </KeyboardColumn>
}

export function OptionEditor<T>({ value, onChange, type, defaultValue }: { value: T, onChange: (value: T | undefined) => void, type: EditorType, defaultValue: () => T }) {
    if (value !== undefined) {
        return <KeyboardRow>
            <Button flat={true} onClick={() => onChange(undefined)}>
                <i className="fa-solid fa-trash"></i>
            </Button>
            <AnyEditor value={value} onChange={onChange} type={type} />
        </KeyboardRow>
    } else {
        return <Button flat={true} onClick={() => onChange(defaultValue())}>
            <i className="fa-solid fa-circle-plus"></i>
        </Button>;
    }
}


export function AnyEditor({ value, onChange, type }: { value: any, onChange: (value: any) => void, type: EditorType }) {
    switch (type.type) {
        case 'float': return <NumberEditor value={value} onChange={onChange} type={NumberEditorType.Float} />;
        case 'int': return <NumberEditor value={value} onChange={onChange} type={NumberEditorType.SignedInteger} />;
        case 'string': return <StringEditor value={value} onChange={onChange} />;
        case 'bool': return <CheckboxEditor value={value} onChange={onChange} />;
        case 'struct': return <StructEditor value={value} onChange={onChange} fields={type.fields} />;
        case 'enum-struct': return <EnumStructEditor value={value} onChange={onChange} variants={type.variants} />;
        case 'enum': return <EnumEditor value={value} onChange={onChange} variants={type.variants} />;
        case 'list': return <ListEditor value={value} onChange={onChange}
            itemEditor={(value, onChange) => <AnyEditor value={value} onChange={onChange} type={type.itemType} />}
            newItem={type.newItem} />;
        case 'option': return <OptionEditor value={value} onChange={onChange} type={type.innerType} defaultValue={type.defaultValue} />;
        case 'entity-data': return <ECSEntityDataEditor value={value} onChange={onChange} />;
        case 'custom': return type.editor(value, onChange);
    }
}
