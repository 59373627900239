import React, { useState } from 'react';
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Page, Row } from "../../Components/Layout";
import { collections, orderByT, queryT, useStreamDocumentById, useStreamQuery, whereT } from '../../FirebaseHooks';
import { DbId, DbInstance, stringifyOwnerId } from 'dims-shared';
import { Link } from '../../Components/Routing';
import { formatDistance } from 'date-fns';
import { Button } from '../../Components/Button';
import { limit } from 'firebase/firestore';
import { Tab, Tabs } from '../../Components/Tabs';
import { AdminInstances } from './Instances';
import { AdminServers } from './Servers';
import { ECSComponentsForOwner } from '../../Components/ECSComponents';

export const AdminPage = () => (
    <Tabs style={{ overflow: 'auto', height: '100%' }}>
        <Tab label="Instances">
            <AdminInstances />
        </Tab>
        <Tab label="Servers">
            <AdminServers />
        </Tab>
        <Tab label="Components">
            <ECSComponentsForOwner ownerId={stringifyOwnerId('sys', '0')} />
        </Tab>
    </Tabs>
);

