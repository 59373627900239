import React from 'react';
import { intoPromise } from '../../Util';
import { RoutingHistory } from '../Routing';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
    link?: string,
    primary?: boolean,
    flat?: boolean,
    toggled?: boolean,
    innerRef?: (el: HTMLElement | null) => void,
}

export function Button({ onClick, link, primary, flat = !!link && !primary, toggled, disabled, children, className, innerRef, ...props }: ButtonProps) {
    let [working, setWorking] = React.useState(false);
    if (link) {
        onClick = (e) => { e.preventDefault(); RoutingHistory.push(link) };
    }
    let handleClick = !onClick ? undefined : async (e: React.MouseEvent<HTMLButtonElement>) => {
        setWorking(true);
        await intoPromise(onClick!(e));
        setWorking(false);
    };
    let classes = [flat ? 'flat' : '', primary ? 'primary' : '', toggled ? 'toggled' : '', className].join(' ');
    return <button onClick={handleClick} disabled={disabled || working} className={classes} ref={innerRef} {...props}>
        {children}
    </button>
}
