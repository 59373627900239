import './index.css';
import React from 'react';
import { DialogScreen, SetScreen } from "../Screen";
import { KeyboardColumn, KeyboardRow } from '../Layout';
import { intoPromise } from '../../Util';
import { Button } from '../Button';

export function Dialog({ children, fullscreen = false, className }: { children?: React.ReactNode, fullscreen?: boolean, className?: string }) {
    return <div className={`Dialog ${className ?? ''} ${fullscreen ? 'DialogFullscreen' : ''}`} onClick={e => e.stopPropagation()}>{children}</div>
}

interface ConfirmProps {
    setScreen: SetScreen,
    title: string,
    onOk: () => void | Promise<void>,
    onCancel?: () => void,
}
export function Confirm({ setScreen, title, onOk, onCancel = () => { } }: ConfirmProps) {
    return <CustomPrompt
        setScreen={setScreen}
        title={title}
        editor={(value, setValue, working) => null}
        defaultValue={null}
        onOk={onOk}
        onCancel={onCancel}
    />
}


interface PromptProps {
    setScreen: SetScreen,
    title: string,
    defaultValue?: string,
    placeholder?: string,
    multiline?: boolean,
    onOk: (value: string) => void | Promise<void>,
    onCancel?: () => void,
}
export function Prompt({ setScreen, title, defaultValue = '', placeholder = 'Enter value', multiline = false, onOk, onCancel = () => { } }: PromptProps) {
    return <CustomPrompt
        setScreen={setScreen}
        title={title}
        editor={(value, setValue, working) =>
            multiline ? <textarea disabled={working} cols={100} rows={10} onChange={e => setValue(e.target.value)}>{value}</textarea> :
                <input type="text" disabled={working} value={value} onChange={e => setValue(e.target.value)} placeholder={placeholder} />}
        defaultValue={defaultValue}
        onOk={onOk}
        onCancel={onCancel}
    />
}

interface FilePromptProps {
    setScreen: SetScreen,
    title: string,
    accept?: string,
    onOk: (value: File) => void | Promise<void>,
    onCancel?: () => void,
}
export function FilePrompt({ setScreen, title, accept, onOk, onCancel = () => { } }: FilePromptProps) {
    return <CustomPrompt<FileList | null>
        setScreen={setScreen}
        title={title}
        editor={(value, setValue, working) =>
            <input type="file" onChange={e => setValue(e.target.files)} accept={accept} />
        }
        defaultValue={null}
        onOk={value => onOk(value!.item(0)!)}
        onCancel={onCancel}
        validate={value => value !== null && value.length === 1 && value.item(0) !== null}
    />
}

interface CustomPromptProps<T> {
    setScreen: SetScreen,
    title: string,
    editor: (value: T, onChange: (value: T) => void, working: boolean) => React.ReactNode | null,
    defaultValue: T,
    onOk: (value: T) => void | Promise<void>,
    onCancel?: () => void,
    validate?: (value: T) => boolean,
}
export function CustomPrompt<T>({ setScreen, title, editor, defaultValue, onOk, onCancel = () => { }, validate = () => true }: CustomPromptProps<T>) {
    let [value, setValue] = React.useState(defaultValue);
    let [working, setWorking] = React.useState(false);
    return <DialogScreen onClose={() => setScreen(null)}>
        <Dialog>
            <KeyboardColumn>
                <h2>{title}</h2>
                {editor(value, setValue, working)}
                <KeyboardRow>
                    <Button disabled={working || !validate(value)} primary={true} onClick={async () => { setWorking(true); await intoPromise(onOk(value)); setScreen(null); }}>Ok</Button>
                    <Button disabled={working} onClick={() => { onCancel(); setScreen(null); }}>Cancel</Button>
                </KeyboardRow>
            </KeyboardColumn>
        </Dialog>
    </DialogScreen>
}
