import * as React from 'react';
import './index.css';

interface LayoutProps { children?: React.ReactNode, className?: string, style?: React.CSSProperties }

function createLayoutClass(layoutClass: string) {
    return ({ children, className = '', style }: LayoutProps) => {
        return <div className={`${layoutClass} ${className}`} style={style}>{children}</div>;
    }
}

export const Row = createLayoutClass('Row');
export const Column = createLayoutClass('Column');
export const KeyboardRow = createLayoutClass('KeyboardRow');
export const KeyboardColumn = createLayoutClass('KeyboardColumn');
export const Section = createLayoutClass('Section');

export const Panel = createLayoutClass('Panel');

export const Page = createLayoutClass('Page');

export const Center = createLayoutClass('Center');
