import * as React from 'react';
import { getApp } from 'firebase/app';
import { collection, CollectionReference, orderBy, doc, limit, getFirestore, Query, where, WhereFilterOp, query, DocumentData, onSnapshot, DocumentSnapshot, QueryConstraint, OrderByDirection, DocumentReference } from "firebase/firestore";
import { Db, DbAsset, DbAssetPack, DbECSComponent, DbId, DbInstance, DbIssue, DbMatchmakingQueue, DbMember, DbMessage, DbNamespace, DbOrganization, DbPipelineJob, DbProfile, DbProject, DbRelease, DbServer } from 'dims-shared';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { Page } from './Components/Layout';
import { listAll, ListResult, StorageReference } from 'firebase/storage';
import { Loadable } from './Util';

// https://github.com/microsoft/TypeScript/issues/20707
export function notNullOrUndefined<T>(x: T | undefined | null): x is T {
    return x !== undefined && x !== null;
}

const db = getFirestore(getApp());
const auth = getAuth();

export const collections = {
    assetTags: collection(db, Db.assetTags) as CollectionReference<{ tags: string[] }>,
    profiles: collection(db, Db.profiles) as CollectionReference<DbProfile>,
    instances: collection(db, Db.instances) as CollectionReference<DbInstance>,
    projects: collection(db, Db.projects) as CollectionReference<DbProject>,
    members: collection(db, Db.members) as CollectionReference<DbMember>,
    assetPacks: collection(db, Db.assetPacks) as CollectionReference<DbAssetPack>,
    assets: collection(db, Db.assets) as CollectionReference<DbAsset>,
    servers: collection(db, Db.servers) as CollectionReference<DbServer>,
    matchmakingQueues: collection(db, Db.matchmakingQueues) as CollectionReference<DbMatchmakingQueue>,
    messages: collection(db, Db.messages) as CollectionReference<DbMessage>,
    issues: collection(db, Db.issues) as CollectionReference<DbIssue>,
    organizations: collection(db, Db.organizations) as CollectionReference<DbOrganization>,
    ecsComponents: collection(db, Db.ecsComponents) as CollectionReference<DbECSComponent>,
    releases: (projectId: string) => collection(db, Db.projects, projectId, Db.releases) as CollectionReference<DbRelease>,
    pipelineJobs: collection(db, Db.pipelineJobs) as CollectionReference<DbPipelineJob>,
    namespaces: collection(db, Db.namespaces) as CollectionReference<DbNamespace>,
};

export interface QueryT<T> extends Query<T> { };
export interface QueryConstraintT<T> extends QueryConstraint { };

/// Typed version of firebase `query`
export function queryT<T>(q: Query<T>, ...queryConstraints: QueryConstraintT<T>[]): QueryT<T> {
    return query(q, ...queryConstraints);
}
/// Typed version of firebase `where`
export function whereT<T, K extends keyof T>(fieldPath: K, opStr: 'in', value: Array<T[K]>): QueryConstraintT<T>;
export function whereT<T, K extends keyof T>(fieldPath: K, opStr: WhereFilterOp, value: T[K]): QueryConstraintT<T>;
export function whereT<T, K extends keyof T>(fieldPath: K, opStr: WhereFilterOp, value: T[K]): QueryConstraintT<T> {
    return where(fieldPath as any, opStr, value);
}
/// Typed version of firebase `orderBy`
export function orderByT<T, K extends keyof T>(fieldPath: K, directionStr?: OrderByDirection): QueryConstraintT<T> {
    return orderBy(fieldPath as any, directionStr);
}

export function useStreamQueryTo<T extends object>(
    query: QueryT<T> | null,
    onRes: (res: Loadable<Array<T & DbId>>) => void,
    docToValue?: (doc: DocumentData) => T
) {
    React.useEffect(() => {
        if (!query) {
            return;
        }
        const unsub = onSnapshot(query, snapshot => {
            onRes({
                status: 'loaded',
                value: docToValue ?
                    snapshot.docs.map(d => ({ id: d.id, ...docToValue(d) })) :
                    snapshot.docs.map(d => ({ id: d.id, ...d.data() as T }))
            })
        }, error => {
            console.log('Firebase query failed:', query, error);
            onRes({ status: 'error', error: 'internal' });
        });
        return unsub;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);
}

export function useStreamQuery<T extends object>(
    query: QueryT<T> | null,
    docToValue?: (doc: DocumentData) => T
): Loadable<Array<T & DbId>> {
    const [res, setRes] = React.useState<Loadable<Array<T & DbId>>>({ status: 'loading' });
    useStreamQueryTo(query, setRes, docToValue);
    return res;
}

export type DocumentErrors = 'no-such-document' | 'internal';

export function useStreamDocumentTo<T extends object>(
    docRef: DocumentReference<T> | undefined,
    setRes: (value: Loadable<T & DbId, DocumentErrors>
    ) => void) {
    React.useEffect(() => {
        if (!docRef) {
            setRes({ status: 'error', error: 'no-such-document', message: 'No such document' });
            return;
        }
        return onSnapshot(docRef, doc => {
            if (!doc.exists()) {
                setRes({ status: 'error', error: 'no-such-document', message: 'No such document' });
            } else {
                setRes({
                    status: 'loaded',
                    value: { ...(doc.data() as T), id: doc.id }
                })
            }
        }, error => {
            console.log('Failed to get document', docRef.id, error);
            setRes({ status: 'error', error: 'internal' })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docRef]);
}

export function useStreamDocument<T extends object>(doc: DocumentReference<T> | undefined):
    Loadable<T & DbId, DocumentErrors> {
    const [res, setRes] = React.useState<Loadable<T & DbId, DocumentErrors>>(
        doc ? { status: 'loading' } : { status: 'error', error: 'no-such-document', message: 'No such document' }
    );
    useStreamDocumentTo(doc, setRes);
    return res;
}
export function useStreamDocumentById<T extends object>(collection: CollectionReference<T>, id: string | undefined) {
    return useStreamDocument(React.useMemo(() => id ? doc(collection, id) : undefined, [collection, id]))
}

export function useStreamDocuments<T extends object>(
    coll: string, ids: string[], { docToValue }: {
        docToValue?: (doc: DocumentSnapshot) => T
    } = {}):
    Loadable<Array<T & { id: string } | undefined>> {
    const [docs, setDocs] = React.useState<null | DocumentSnapshot[]>(null);
    const idsString = ids.join(',');
    React.useEffect(() => {
        const idsList = idsString.length > 0 ? idsString.split(',') : [];
        if (idsList.length === 0) {
            setDocs([]);
            return;
        }
        const unsubs: Array<() => void> = [];
        const docsArray: DocumentSnapshot[] = new Array(idsList.length).fill(undefined);
        for (let i = 0; i < idsList.length; i++) {
            unsubs.push(onSnapshot(doc(collection(db, coll), idsList[i]), snapshot => {
                docsArray[i] = snapshot;
                if (docsArray.filter(x => x === undefined).length === 0) {
                    setDocs([...docsArray]);
                }
            }));
        }
        return () => {
            unsubs.forEach(unsub => unsub());
        };
    }, [coll, idsString]);
    if (docs === null) {
        return { status: 'loading' };
    } else {
        return {
            status: 'loaded',
            value: docs.map(d => d.exists() ? { ...(docToValue ? docToValue(d) : (d.data() as T)), id: d.id } : undefined)
        };
    }
}

export function useAuthLoading() {
    let [user, setUser] = React.useState<null | User | 'loading'>('loading');
    React.useEffect(() => {
        onAuthStateChanged(auth, user => setUser(user));
    }, []);
    return user;
}
export function useAuth() {
    let auth = useAuthLoading();
    if (auth === 'loading' || !auth) {
        return null;
    } else {
        return auth;
    }
}
export function useMemberships(userId: string) {
    let memberships = useStreamQuery(React.useMemo(() => queryT(collections.members, whereT('userId', '==', userId), orderByT('sidebarOrder', 'desc')), [userId]));
    if (memberships.status === 'loaded') {
        return memberships.value;
    } else {
        return [];
    }
}

export function useListAllFiles(storageRef: StorageReference | null) {
    let [files, setFiles] = React.useState<null | ListResult>(null);
    React.useEffect(() => {
        (async () => {
            if (storageRef) {
                setFiles(await listAll(storageRef));
            } else {
                setFiles(null);
            }
        })();
    }, [storageRef]);
    return files;
}
