import * as React from 'react';
import Route from 'route-parser';
import { EventDispatcher } from '../Util';

function getRoute() {
    return window.location.pathname + window.location.search;
}

class RoutingHistoryImpl {
    onChange = new EventDispatcher();
    push(url: string) {
        window.history.pushState(null, '', url);
        this.onChange.dispatch();
    }
    replace(url: string) {
        window.history.replaceState(null, '', url);
        this.onChange.dispatch();
    }
}
export const RoutingHistory = new RoutingHistoryImpl();

export function useRoute() {
    const [route, setRoute] = React.useState(getRoute());
    React.useEffect(() => {
        const handlePopstate = (e: PopStateEvent) => {
            e.preventDefault();
            setTimeout(() => {
                setRoute(getRoute());
            }, 0);
        }
        const handleChange = () => {
            setTimeout(() => {
                setRoute(getRoute());
            }, 0);
        }
        window.addEventListener('popstate', handlePopstate);
        RoutingHistory.onChange.attach(handleChange);
        return () => {
            window.removeEventListener('popstate', handlePopstate);
            RoutingHistory.onChange.detach(handleChange);
        }
    }, []);
    return route;
}
interface RouterProps {
    routes: { [path: string]: (match: { [key: string]: string }) => React.ReactElement },
    notFound?: () => React.ReactElement
}
export function Router({ routes, notFound }: RouterProps) {
    const route = useRoute();
    for (const key of Object.keys(routes)) {
        const m = new Route(key).match(route);
        if (m) {
            console.log(`Router rendering path ${key}`);
            return routes[key](m);
        }
    }
    if (notFound) {
        return notFound();
    } else {
        return null;
    }
}

/**
 * @deprecated Use Button with link instead
 */
export function Link({ to, flat = true, primary = false, className, style, children }: { to: string, flat?: boolean, className?: string, style?: React.CSSProperties, children?: any, primary?: boolean }) {
    // eslint-disable-next-line
    return <button className={[primary ? 'primary' : flat ? 'flat' : '', className ?? ''].join(' ')} style={style} onClick={e => {
        e.preventDefault();
        RoutingHistory.push(to);
    }}>{children}</button>
}
