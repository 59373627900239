import React from 'react';
import './index.css';
import { ScreenContainer, ScreenType, SetScreen } from '../Screen';
import { Button } from '../Button';

export type DropdownAttachment = 'horizontal' | 'vertical';


export function DropdownScreen({ setScreen, originator, attachment = 'vertical', children }: { setScreen: SetScreen, originator: HTMLElement, attachment?: DropdownAttachment, children?: React.ReactNode }) {
    return <div className="DropdownFader" onClick={e => { e.stopPropagation(); setScreen(null); }}>
        <div className='DropdownFloat' style={dropdownPositionStyle(originator, attachment)}>
            {children}
        </div>
    </div>
}

function dropdownPositionStyle(originator: HTMLElement, attachment: DropdownAttachment) {
    const bounding = originator.getBoundingClientRect();
    const style: React.CSSProperties = {};
    if (attachment === 'vertical') {
        if (bounding.left < window.innerWidth / 2) {
            style.left = bounding.left + 'px';
        } else {
            style.right = (window.innerWidth - bounding.right) + 'px';
        }
        if (bounding.bottom < window.innerHeight / 2) {
            return {
                ...style,
                top: bounding.bottom + 'px',
                maxHeight: (window.innerHeight - bounding.bottom - 3) + 'px'
            };
        } else {
            return {
                ...style,
                bottom: (window.innerHeight - bounding.top) + 'px',
                maxHeight: (bounding.top - 3) + 'px'
            };
        }
    } else {
        if (bounding.left < window.innerWidth / 2) {
            style.left = bounding.right + 'px';
            style.maxWidth = (window.innerWidth - bounding.left) + 'px';
        } else {
            style.right = (window.innerWidth - bounding.left) + 'px';
            style.maxWidth = (bounding.left - 3) + 'px';
        }
        if (bounding.bottom < window.innerHeight / 2) {
            return {
                ...style,
                top: bounding.top + 'px'
            };
        } else {
            return {
                ...style,
                bottom: (window.innerHeight - bounding.bottom) + 'px'
            };
        }
    }
}

interface DropdownProps {
    className?: string;
    children?: React.ReactNode,
    dropdown: (setScreen: SetScreen) => React.ReactNode;
    attachment?: DropdownAttachment,
    flat?: boolean,
}

export function Dropdown({ className, children, dropdown, attachment, flat }: DropdownProps) {
    let container = React.useRef<HTMLElement | null>(null);
    let [screen, setScreen] = React.useState<ScreenType>(null);
    return (
        <Button
            className={['Dropdown', className ?? ''].join(' ')}
            innerRef={e => container.current = e}
            flat={flat}
            onClick={() => setScreen(<DropdownScreen setScreen={setScreen} originator={container.current!} attachment={attachment} >{dropdown(setScreen)}</DropdownScreen>)}
        >
            <ScreenContainer screen={screen} />
            {children}
        </Button>
    );
}

export function Tooltip({ tooltip, className, attachment = 'vertical', children }: { tooltip: () => React.ReactNode, className?: string, attachment?: DropdownAttachment, children?: React.ReactNode }) {
    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
    let container = React.useRef<HTMLElement | null>(null);
    let tooltipContent = null;
    if (showTooltip && container.current) {
        let inner = tooltip();
        if (inner) {
            tooltipContent = <div className="Tooltip" style={dropdownPositionStyle(container.current, attachment)}>{inner}</div>;
        }
    }
    return <div className={`TooltipContainer ${className ?? ''}`}
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
        ref={e => container.current = e}>
        {children}
        {tooltipContent && <ScreenContainer screen={tooltipContent} />}
    </div>
}
