import './index.css';
import { Button } from "../../Components/Button";
import { KeyboardColumn, KeyboardRow, Page, Panel } from "../../Components/Layout";
import { Loading } from "../../Components/Loading";
import { collections, useStreamDocument, useStreamQuery } from "../../FirebaseHooks";
import algoliasearch from 'algoliasearch/lite';
import { Configure, HierarchicalMenu, InstantSearch, RefinementList, useHits, useInfiniteHits, useRefinementList, useSearchBox, useToggleRefinement } from 'react-instantsearch-hooks-web';
import { doc, getDoc } from "firebase/firestore";
import { CheckboxEditor } from '../../Components/Editor';
import { useRoute } from '../../Components/Routing';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DbAsset, stringifyOwnerId } from 'dims-shared';


const searchClient = algoliasearch('M2684B9XV0', '4e3b40feb1f720a476be04c572cf587b');


async function getAssetCollectionRecursive(id: string): Promise<Array<DbAsset>> {
    let asset = (await getDoc(doc(collections.assets, id))).data()!;
    if (asset.isCollection) {
        let res = await Promise.all(asset.collection!.map(async (id) => {
            return await getAssetCollectionRecursive(id);
        }));
        return res.flat();
    } else {
        return [asset];
    }
}

function appSelectAssetSingle(name: string, content: string) {
    console.log('Select asset', content);
    if ((window as any).ipc) {
        (window as any).ipc.postMessage(JSON.stringify({
            type: 'SelectAssetContent',
            content: content,
            name
        }));
    } else {
        navigator.clipboard.writeText(JSON.stringify({
            display_name: name,
            urls: [content]
        }, null, 2))
    }
}
function appSelectAssetCollection(name: string, contents: string[]) {
    console.log('Select asset collection', contents);
    if ((window as any).ipc) {
        (window as any).ipc.postMessage(JSON.stringify({
            type: 'SelectAssetCollection',
            contents: contents,
            name,
        }));
    } else {
        navigator.clipboard.writeText(JSON.stringify({
            display_name: name,
            urls: contents
        }, null, 2))
    }
}

function Hit({ hit }: { hit: { objectID: string, name: string, type: string, isCollection: boolean, tags: string[], previewUrl: string, content?: string } }) {
    console.log(hit);
    return <Panel>
        <KeyboardColumn style={{ alignItems: 'center' }}>
            {hit.isCollection ? <AssetCollection id={hit.objectID} /> :
                <Button onClick={() => appSelectAssetSingle(hit.name, hit.content!)}>
                    {hit.previewUrl ? <img src={hit.previewUrl} alt="Hit" width={100} /> : <i className="fa-solid fa-scroll"></i>}
                </Button>}
            <KeyboardColumn style={{ maxWidth: 150, alignItems: 'center' }}>
                {hit.name}
            </KeyboardColumn>
            {/* <small>{hit.tags.join(' ')}</small> */}
        </KeyboardColumn>
    </Panel>;
}
function AssetCollection({ id }: { id: string }) {
    let [index, setIndex] = useState(0);
    let [assets, setAssets] = useState<DbAsset[]>([]);
    let [rootAsset, setRootAsset] = useState<DbAsset | null>(null);
    useEffect(() => {
        (async () => {
            setAssets(await getAssetCollectionRecursive(id));
            setRootAsset((await getDoc(doc(collections.assets, id))).data()!);
        })();
    }, [id]);

    let len = assets.length + 1;
    let realIndex = index % len;
    const onSelect = async () => {
        if (realIndex === 0) {
            appSelectAssetCollection(rootAsset?.name ?? '', assets.map(asset => asset.content!));
        } else {
            let asset = assets[realIndex - 1];
            appSelectAssetSingle(asset.name, asset.content!);
        }
    };
    let asset = assets[Math.max(0, realIndex - 1)];
    return <KeyboardColumn style={{ alignItems: 'center' }}>
        <KeyboardRow>
            <Button flat={true} onClick={() => setIndex(((index - 1) + len) % len)}><i className="fa-solid fa-chevron-left"></i></Button>
            <Button onClick={onSelect}>
                {asset && asset.previewUrl && <img src={asset.previewUrl} alt="Preview" width={100} />}
            </Button>
            <Button flat={true} onClick={() => setIndex((index + 1) % len)}><i className="fa-solid fa-chevron-right"></i></Button>
        </KeyboardRow>
        {realIndex === 0 ? <small>random ({assets.length})</small> : <small>{realIndex} / {assets.length}</small>}
    </KeyboardColumn>
}

function SearchBox() {
    let { refine } = useSearchBox();
    let [query, setQuery] = useState('');
    return <input
        type="text"
        className='SearchBox'
        placeholder='Search assets...'
        value={query}
        onChange={e => {
            refine(e.target.value);
            setQuery(e.target.value);
        }}
    />
}

function Hits() {
    const { hits, isLastPage, showMore } = useInfiniteHits();
    const sentinelRef = useRef(null);

    useEffect(() => {
        if (sentinelRef.current !== null) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !isLastPage) {
                        showMore();
                    }
                });
            });

            observer.observe(sentinelRef.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [isLastPage, showMore]);


    return <KeyboardRow className='Hits' >
        {hits.map(hit => <Hit hit={hit as any} key={hit.objectID} />)}
        <div ref={sentinelRef} className='HitsSentinel' />
    </KeyboardRow>
}

export function AppAssetBrowser({ type, projectId }: { type: string, projectId: string }) {
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.key === 'Tab') {
                e.preventDefault();
                e.stopPropagation();
                (window as any).ipc.postMessage(JSON.stringify({
                    type: 'Back',
                }));
                return false;
            }
        };
        window.addEventListener('keydown', handler);
        return () => {
            window.removeEventListener('keydown', handler);
        }
    });
    return <InstantSearch searchClient={searchClient} indexName="prod_assets">
        <Configure filters={`type:${type} AND hidden:false AND (public:true OR ownerId:"${stringifyOwnerId('prj', projectId)}")`} hitsPerPage={100} />
        <KeyboardRow className="AppAssetBrowser">
            <KeyboardColumn style={{ marginTop: 50, width: 200 }}>
                <b>Categories</b>
                <HierarchicalMenu attributes={[
                    'categories_lvl0',
                    'categories_lvl1',
                    'categories_lvl2',
                ]} />
                <b>Tags</b>
                <RefinementList attribute="tags" />
            </KeyboardColumn>
            <KeyboardColumn className='AppAssetBrowserRight'>
                <SearchBox />
                <Hits />
            </KeyboardColumn>
        </KeyboardRow>
    </InstantSearch>;
}
