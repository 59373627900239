import './index.css';
import { DbId, DbMessage } from 'dims-shared';
import { getApp } from 'firebase/app';
import { addDoc, CollectionReference, deleteDoc, doc, getFirestore, Timestamp } from 'firebase/firestore';
import React from 'react';
import { collections, orderByT, queryT, useAuth, useStreamQuery } from '../../FirebaseHooks';
import { Column, KeyboardColumn, KeyboardRow, Panel, Row } from '../Layout';
import { UserName, UserProfileImage } from '../User';
import { formatRelative } from 'date-fns';
import { Button } from '../Button';
import { Loading } from '../Loading';

const db = getFirestore(getApp());

export function Chat({ collection }: { collection: CollectionReference<DbMessage> }) {
    return <KeyboardColumn className='Chat'>
        <ChatHistory collection={collection} />
        <NewMessage collection={collection} />
    </KeyboardColumn>;
}

function ChatHistory({ collection }: { collection: CollectionReference<DbMessage> }) {
    let messages = useStreamQuery(React.useMemo(() => queryT(collection, orderByT('created')), [collection]));
    return <Loading values={{ messages }}>{({ messages }) => <KeyboardColumn className='ChatHistory'>
        {groupMessages(messages).map(group => <MessageGroup key={group.messages[0].id} group={group} collection={collection} />)}
    </KeyboardColumn>}</Loading>
}
interface MessageGrouping {
    userId: string,
    start: Date,
    messages: Array<DbMessage & DbId>
}
function groupMessages(messages: Array<DbMessage & DbId>) {
    let grouped: MessageGrouping[] = [];
    for (const message of messages) {
        let last = grouped[grouped.length - 1];
        if (last && last.userId === message.userId &&
            (message.created.toDate().getTime() - last.start.getTime()) < 1000 * 60 * 10) {
            last.messages.push(message);
        } else {
            grouped.push({
                userId: message.userId,
                start: message.created.toDate(),
                messages: [message]
            });
        }
    }
    return grouped;
}
function MessageGroup({ group, collection }: { group: MessageGrouping, collection: CollectionReference<DbMessage> }) {
    return <Column className='MessageGroup'>
        {group.messages.map((message, i) => <Message key={message.id} index={i} message={message} collection={collection} />)}
    </Column>;
}

function Message({ message, index, collection }: { message: DbMessage & DbId, index: number, collection: CollectionReference<DbMessage> }) {
    let auth = useAuth();
    let body = <Row className='MessageBody'>{message.content}</Row>;
    let content = index === 0 ? <KeyboardRow style={{ alignItems: 'end' }} className="MessageGroupHead">
        <UserProfileImage userId={message.userId} large={true} />
        <Column style={{ flex: 1, justifyContent: 'space-between' }}>
            <KeyboardRow>
                <div className='MessageUserName'><UserName userId={message.userId} /></div>
                <small>{formatRelative(message.created.toDate(), new Date())}</small>
            </KeyboardRow>
            {body}
        </Column>
    </KeyboardRow> : <Row style={{ paddingLeft: 50 }}>{body}</Row>;
    return <Row className='Message'>
        {content}
        {auth?.uid === message.userId && <Row className='MessageControls'>
            <Button onClick={async () => await deleteDoc(doc(collection, message.id))}>
                <i className="fa-solid fa-trash"></i>
            </Button>
        </Row>}
    </Row>;
}


function MessageEditor({ initValue, onChange, onKeyDown, placeholder = '' }: { initValue?: string, onChange: (value: string) => void, onKeyDown?: (e: React.KeyboardEvent) => void, placeholder?: string }) {
    const contentDiv = React.useRef<HTMLDivElement>(null);
    const [showPlaceholder, setShowPlaceholder] = React.useState(true);
    React.useEffect(() => {
        if (contentDiv.current && initValue) {
            contentDiv.current.innerText = initValue;
        }
    }, [initValue]);
    return <div className="MessageEditor">
        {showPlaceholder && <div className="MessageEditorPlaceholder">{placeholder}</div>}
        <div
            className="MessageEditorContent"
            contentEditable={true}
            onInput={e => {
                const text = (e.target as HTMLDivElement).innerText;
                onChange(text);
                setShowPlaceholder(text.length === 0);
            }}
            onKeyDown={onKeyDown}
            ref={contentDiv}
        />
    </div>
}

function NewMessage({ collection }: { collection: CollectionReference<DbMessage> }) {
    let auth = useAuth()!;
    const [comment, setComment] = React.useState('');
    const submit = async () => {
        setComment('');
        addDoc(collection, {
            userId: auth.uid,
            content: comment,
            created: Timestamp.now()
        });
        // const now = firebase.firestore.Timestamp.now();
        // await firebase.firestore().collection(Db.comments).add(full<DbComment>({
        //     threadId,
        //     created: now,
        //     authorId: loggedInUser.uid,
        //     content: comment,
        //     deleted: false,
        //     history: [{
        //         userId: loggedInUser.uid,
        //         time: now,
        //         action: { type: 'create', content: comment }
        //     }]
        // }));
    }
    return <Panel>
        <MessageEditor
            onChange={setComment}
            placeholder="Write message"
            onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    submit();
                    (e.target as HTMLDivElement).innerText = '';
                }
            }}
        />
    </Panel>
}
