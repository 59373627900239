import { Column, KeyboardColumn, KeyboardRow } from "../Layout";
import React from 'react';
import { CheckboxEditor } from "../Editor";
import { arrayReplaceIndex, arrayToggle } from "../../Util";

const CATEGORIES = {
    Characters: {},
    Buildings: {},
    Nature: {
        Plants: {
            Trees: {},
            Grass: {},
            Bushes: {},
        },
        Rocks: {}
    },
    Weapons: {
    },
    Vehicles: {
        Cars: {},
        Boats: {}
    },
};

export function CategoriesEditor({ value = [], onChange }: { value: string[][], onChange: (value: string[][]) => void }) {
    for (let i = 0; i < 3; i++) {
        if (!value[i]) {
            value[i] = [];
        }
    }
    return <CategoriesLevelEditor value={value} onChange={onChange} categories={CATEGORIES} level={0} path="" />
}

function CategoriesLevelEditor({ value, onChange, categories, level, path }: { value: string[][], onChange: (value: string[][]) => void, categories: { [key: string]: any }, level: number, path: string }) {
    let levelValue = value[level] ?? [];
    return <Column className="CategoriesLevelEditor">{Object.keys(categories).map(category => {
        let categoryPath = path ? `${path} > ${category}` : category;
        return <Column>
            <KeyboardRow>
                <CheckboxEditor value={levelValue.indexOf(categoryPath) !== -1} onChange={checked => {
                    onChange(arrayReplaceIndex(value, level, arrayToggle(levelValue, categoryPath)));
                }} />
                {category}
            </KeyboardRow>
            <CategoriesLevelEditor value={value} onChange={onChange} categories={categories[category]} level={level + 1} path={categoryPath} />
        </Column>;
    })}</Column>
}
