import { Loadable } from "../Util";
import { Page, Row } from "./Layout";

export function Loading<T>({ values, children }: { values: { [K in keyof T]: Loadable<T[K]> }, children: (values: T) => React.ReactNode }) {
    let res: any = {};
    for (const [key, value] of Object.entries(values)) {
        let val = value as Loadable<any>;
        if (val.status === 'loading') {
            return <Row>Loading...</Row>;
        } else if (val.status === 'error') {
            return <Row>Error: {val.message}</Row>;
        } else {
            res[key] = val.value;
        }
    }
    return <>{children(res as T)}</>;
}
