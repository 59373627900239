import { formatDistance, formatRelative } from "date-fns";
import { useState } from "react";
import { useInterval } from "../Hooks";

export function DateFormatDistance({ date }: { date: Date }) {
    let [string, setString] = useState(formatDistance(date, new Date()));
    useInterval(() => { setString(formatDistance(date, new Date())); }, 1000);
    return <>{string}</>;
}


export function toByteUnit(bytes: number): String {
    if (bytes < 1024 * 10) {
        return `${Math.floor(bytes)} b`;
    } else if (bytes < 1024 * 1024 * 10) {
        return `${Math.floor(bytes / 1024)} kb`;
    } else if (bytes < 1024 * 1024 * 1024 * 10) {
        return `${Math.floor(bytes / 1024 / 1024)} mb`;
    } else {
        return `${Math.floor(bytes / 1024 / 1024 / 1024)} gb`;
    }
}
