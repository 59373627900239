import { DbECSComponent, DbECSComponentType, DbId, parseOwnerId } from "dims-shared";
import React, { useEffect, useMemo, useState } from "react";
import { collections, queryT, useAuth, useStreamQuery, whereT } from "../../FirebaseHooks";
import { KeyboardColumn, KeyboardRow, Row } from "../Layout";
import { addDoc, deleteDoc, doc, getDoc, getDocs, setDoc, Timestamp, UpdateData, updateDoc } from "firebase/firestore";
import { DialogScreen, ScreenContainer, ScreenType } from "../Screen";
import { Dialog } from "../Dialog";
import { Button } from "../Button";
import slugify from 'slugify';
import { EditableString } from "../Dialog/Buttons";
import { Loading } from "../Loading";
import { AnyEditor, CheckboxEditor, EditorType } from "../Editor";
import { Tooltip } from "../Dropdown";
import { ValidationCheckmark } from "../Form";

export function ECSComponentsForOwner({ ownerId }: { ownerId: string }) {
    let [screen, setScreen] = useState<ScreenType>(null);
    let components = useStreamQuery(
        useMemo(
            () =>
                queryT(
                    collections.ecsComponents,
                    whereT("ownerId", "==", ownerId),
                    whereT("deleted", "==", false)
                ),
            [ownerId]
        )
    );
    return (
        <KeyboardColumn style={{ alignItems: "start" }}>
            <ScreenContainer screen={screen} />
            <KeyboardRow>
                <Button
                    primary={true}
                    onClick={() =>
                        setScreen(
                            <CreateECSComponentScreen
                                onClose={() => {
                                    setScreen(null);
                                }}
                                ownerId={ownerId}
                            />
                        )
                    }
                >
                    New component
                </Button>
                <Button
                    primary={true}
                    onClick={() =>
                        setScreen(
                            <BulkImportECSComponentScreen
                                onClose={() => {
                                    setScreen(null);
                                }}
                                ownerId={ownerId}
                            />
                        )
                    }
                >
                    Bulk-import components
                </Button>
            </KeyboardRow>
            <Loading values={{ components }}>
                {({ components }) => <ECSComponents components={components} />}
            </Loading>
        </KeyboardColumn>
    );
}


export function ECSComponents({
    components,
}: {
    components: (DbECSComponent & DbId)[];
}) {
    const sortedComponents = [...components].sort((a, b) =>
        a.id.localeCompare(b.id)
    );

    return (
        <table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Public</th>
                </tr>
            </thead>
            <tbody>
                {
                // NOTE(mithun): the `as any` here is because TypeScript provides TS2590 errors instead.
                // Feel free to try fixing this!
                sortedComponents.map((c) => (
                    <tr>
                        <td><small>{c.id}</small></td>
                        <td><EditableString onOk={name => updateDoc(doc(collections.ecsComponents, c.id) as any, { name })}>{c.name}</EditableString></td>
                        <td><EditableString onOk={description => updateDoc(doc(collections.ecsComponents, c.id) as any, { description })}>{c.description}</EditableString></td>
                        <td>{componentTypeToString(c.type)}</td>
                        <td><CheckboxEditor value={c.public} onChange={value => updateDoc(doc(collections.ecsComponents, c.id) as any, { public: value })} /></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

const DbECSComponentPrimitiveTypeType: EditorType = {
    type: 'enum-struct',
    variants: {
        Empty: {},
        Bool: {},
        EntityId: {},
        F32: {},
        F64: {},
        Mat4: {},
        I32: {},
        Quat: {},
        String: {},
        U32: {},
        U64: {},
        Vec2: {},
        Vec3: {},
        Vec4: {},
        ObjectRef: {},
        EntityUid: {},
    }
}

const DbECSComponentTypeType: EditorType = {
    type: 'enum-struct',
    variants:  {
        ...DbECSComponentPrimitiveTypeType.variants,
        Enum: { fields: { variants: { type: { type: 'list', itemType: { type: 'string' }, newItem: () => '' } } } },
        Vec: { fields: { variants: { type: DbECSComponentPrimitiveTypeType } }},
        Option: { fields: { variants: { type: DbECSComponentPrimitiveTypeType } }}
    }
}

function componentTypeToString(componentType: DbECSComponentType): string {
    switch (componentType.type) {
        case 'Enum':
            return `Enum(${componentType.variants.join('|')})`;
        case 'Vec':
            return `Vec<${componentType.variants.type}>`;
        case 'Option':
            return `Option<${componentType.variants.type}>`;
        default:
            return componentType.type;
    }
}

function validateNamespace(namespace: string): boolean {
    if (!/^[a-z0-9_:]*$/.test(namespace)) {
        return false;
    }
    if (namespace[0] === ':' || namespace[namespace.length - 1] === ':') {
        return false;
    }
    let run = 0;
    for (let i = 0; i < namespace.length; i++) {
        if (namespace[i] === ':') {
            run += 1;
            if (run > 2) {
                return false;
            }
        } else {
            if (run === 1) {
                return false;
            }
            run = 0;
        }
    }
    return true;
}

function validateName(name: string) {
    return name.length > 0;
}

function validateIdSegment(id: string) {
    return id.length > 0 && /^[a-z0-9_]*$/.test(id);
}


export function CreateECSComponentScreen({
    onClose,
    ownerId,
}: {
    onClose: (componentId?: string) => void;
    ownerId: string;
}) {
    let [name, setName] = React.useState<string>("");
    let [id, setId] = React.useState<string>("");
    let [description, setDescription] = React.useState<string>("");
    let [type, setType] = React.useState<DbECSComponentType>({type: "Empty"});
    let [public_, setPublic] = React.useState(false);
    let [namespace, setNamespace] = useState('');

    let idValid = validateIdSegment(id);
    let namespaceValid = validateNamespace(namespace);

    let [baseNamespace, setBaseNamespace] = useState('');
    useEffect(() => {
        (async () => {
            let owner = parseOwnerId(ownerId);
            switch (owner.type) {
                case 'org': {
                    setBaseNamespace((await getDoc(doc(collections.organizations, owner.id))).data()!.namespace);
                    break;
                }
                case 'prj': {
                    setBaseNamespace((await getDoc(doc(collections.projects, owner.id))).data()!.namespace);
                    break;
                }
                case 'sys': {
                    setBaseNamespace('core');
                    break;
                }
                default: {
                    throw new Error('Not implemented yet');
                }
            }
        })()
    }, [ownerId]);

    let fullId = [baseNamespace, namespace, id].filter(x => x).join('::');

    let auth = useAuth();
    if (!auth) {
        return <Row>Need to be logged in</Row>;
    }
    let onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        (async () => {
            await setDoc(doc(collections.ecsComponents, fullId), {
                name,
                description,
                type,
                public: public_,
                created: Timestamp.now(),
                ownerId,
                deleted: false,
            });
            onClose(fullId);
        })();
    };

    const nameValid = validateName(name);
    const valid = nameValid && idValid && namespaceValid;
    return (
        <DialogScreen onClose={onClose}>
            <Dialog>
                <KeyboardColumn>
                    <h1>New Component</h1>
                    <KeyboardRow>{fullId}</KeyboardRow>
                    <KeyboardColumn>
                        <KeyboardRow>
                            Name:{" "}
                            <input
                                type="text"
                                placeholder="Empty"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    let subId = slugify(e.target.value, {
                                        replacement: '_',
                                        strict: true,
                                        lower: true
                                    });
                                    setId(subId);
                                }}
                            />
                            <ValidationCheckmark valid={nameValid} tooltip={"Name must be non-empty"} />
                        </KeyboardRow>

                        <KeyboardRow>
                            Description:{" "}
                            <input
                                type="text"
                                placeholder="Empty"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </KeyboardRow>
                        <KeyboardRow>
                            Public:{" "}
                            <input
                                type="checkbox"
                                checked={public_}
                                onChange={(e) => setPublic(!public_)}
                            />
                        </KeyboardRow>
                        <KeyboardRow>
                            Id:{" "}
                            <input
                                type="text"
                                placeholder="Empty"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                            />
                            <ValidationCheckmark valid={idValid} tooltip={"Id must contain lowercase letters, numbers and _ only"} />
                        </KeyboardRow>
                        <KeyboardRow>
                            Namespace:{" "}
                            <input
                                type="text"
                                placeholder="Empty"
                                value={namespace}
                                onChange={(e) => setNamespace(e.target.value)}
                            />
                            <ValidationCheckmark valid={namespaceValid} tooltip={"Namespace must contain lowercase letters, numbers, :: and _ only"} />
                        </KeyboardRow>
                        <KeyboardRow>
                            Type:{" "}
                            <AnyEditor value={type} onChange={setType} type={DbECSComponentTypeType} />
                        </KeyboardRow>
                        <Row>
                            <Button
                                onClick={onSubmit}
                                primary={true}
                                disabled={!valid}
                            >
                                Create component
                            </Button>
                        </Row>
                    </KeyboardColumn>
                </KeyboardColumn>
            </Dialog>
        </DialogScreen>
    );
}

export function BulkImportECSComponentScreen({
    onClose,
    ownerId,
}: {
    onClose: (componentId?: string) => void;
    ownerId: string;
}) {
    let [json, setJson] = React.useState<string>("");
    let parsedJson: unknown | null = null;
    const errors = [];

    if (json.length > 0) {
        try {
            parsedJson = JSON.parse(json);
        } catch (e) {
            if (e instanceof Error) {
                errors.push(e.toString());
            } else {
                throw e;
            }
        }
    }

    let [baseNamespace, setBaseNamespace] = useState('');
    useEffect(() => {
        (async () => {
            let owner = parseOwnerId(ownerId);
            switch (owner.type) {
                case 'org': {
                    setBaseNamespace((await getDoc(doc(collections.organizations, owner.id))).data()!.namespace);
                    break;
                }
                case 'prj': {
                    setBaseNamespace((await getDoc(doc(collections.projects, owner.id))).data()!.namespace);
                    break;
                }
                case 'sys': {
                    setBaseNamespace('core');
                    break;
                }
                default: {
                    throw new Error('Not implemented yet');
                }
            }
        })()
    }, [ownerId]);

    let validatedJson: { id: string, name: string, description?: string, type: DbECSComponentType, public: boolean }[] = [];
    try {
        if (parsedJson instanceof Array) {
            for (const component of parsedJson) {
                let id = component.id;
                if (typeof id !== 'string') {
                    errors.push(`Component '${id}' has a non-string ID`);
                    continue;
                }

                if (!id.startsWith(`${baseNamespace}::`)) {
                    errors.push(`Component '${id}' does not belong to this namespace`);
                    continue;
                }

                if (id.split('::').some(a => !validateIdSegment(a))) {
                    errors.push(`Component '${id}' has an invalid ID`);
                    continue;
                }

                if (typeof component !== 'object') {
                    errors.push(`Component '${id}' is not an object`);
                    continue;
                }

                if (!component.name || !validateName(component.name)) {
                    errors.push(`Component '${id}' has an invalid name`);
                    continue;
                }

                if (typeof component.public !== 'boolean') {
                    errors.push(`Component '${id}' expected to have a 'public' boolean`);
                    continue;
                }

                if (typeof component.type !== 'object') {
                    errors.push(`Component '${id}' should have an object for the type`);
                    continue;
                }

                if (componentTypeToString(component.type).length === 0) {
                    errors.push(`Component '${id}' has an invalid type`);
                    continue;
                }

                validatedJson.push(component);
            }
        } else {
            errors.push("JSON is not an array of components");
        }
    } catch (err) {
        if (err instanceof Error) {
            errors.push(err.toString());
        }
    }

    let auth = useAuth();
    if (!auth) {
        return <Row>Need to be logged in</Row>;
    }
    let onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        (async () => {
            let promises = validatedJson.map(component =>
                setDoc(doc(collections.ecsComponents, component.id), {
                    name: component.name,
                    description: component.description || "No description provided",
                    type: component.type,
                    public: component.public,
                    created: Timestamp.now(),
                    ownerId,
                    deleted: false,
                })
            );
            await Promise.all(promises);
            onClose();
        })();
    };

    return (
        <DialogScreen onClose={onClose}>
            <Dialog>
                <KeyboardColumn>
                    <h1>New Components</h1>
                    <KeyboardRow>Base namespace: {baseNamespace}</KeyboardRow>
                    <KeyboardColumn>
                        <KeyboardRow>
                            <textarea
                                placeholder=""
                                value={json}
                                onChange={(e) => {
                                    setJson(e.target.value);
                                }}
                            ></textarea>
                        </KeyboardRow>
                        <Row>
                            {errors.length === 0 && parsedJson !== null ? (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Type</th>
                                            <th>Public</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {validatedJson.map((c) => (
                                            <tr>
                                                <td>
                                                    <small>{c.id}</small>
                                                </td>
                                                <td>{c.name}</td>
                                                <td>{c.description}</td>
                                                <td>{componentTypeToString(c.type)}</td>
                                                <td><input type="checkbox" checked={c.public} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <ul>
                                    {errors.map((e) => <li>{e}</li>)}
                                </ul>
                            )}
                        </Row>
                        <Row>
                            <Button
                                onClick={onSubmit}
                                primary={true}
                                disabled={errors.length > 0}
                            >
                                Create components
                            </Button>
                        </Row>
                    </KeyboardColumn>
                </KeyboardColumn>
            </Dialog>
        </DialogScreen>
    );
}
