import { onSnapshot, updateDoc } from 'firebase/firestore';
import React from 'react';
import { KeyboardColumn, KeyboardRow, Panel, Row } from '../Components/Layout';
import { Loading } from '../Components/Loading';
import { Link } from '../Components/Routing';
import { collections, queryT, useStreamDocumentById, useStreamQuery, whereT } from '../FirebaseHooks';
import { useProjectThumbnailUrl } from './Project';


export function UserProjects({ userId }: { userId: string }) {
    let memberships = useStreamQuery(React.useMemo(() => queryT(collections.members, whereT('userId', '==', userId), whereT('type', '==', 'project')), [userId]));
    return <KeyboardRow>
        <Loading values={{ memberships }}>{({ memberships }) =>
            memberships.map(membership => {
                if (membership.type === 'project') {
                    return <Row><ProjectCard key={membership.id} projectId={membership.itemId} /></Row>;
                } else {
                    return null;
                }
            })
        }</Loading>
    </KeyboardRow >;
}


function ProjectCard({ projectId }: { projectId: string }) {
    let project = useStreamDocumentById(collections.projects, projectId);
    let thumbnailUrl = useProjectThumbnailUrl(projectId);
    if (project.status !== 'loaded') {
        return <Row>...</Row>;
    }
    let thumbnailWidth = 640 * 0.25;
    let thumbnailHeight = 360 * 0.25;
    return <Link flat={true} to={`/projects/${projectId}`} ><Panel>
        <KeyboardColumn>
            {thumbnailUrl ? <img src={thumbnailUrl} alt="Thumbnail" width={thumbnailWidth} height={thumbnailHeight} /> : <div style={{ width: thumbnailWidth, height: thumbnailHeight }} />}
            {project.value.name}
        </KeyboardColumn>
    </Panel></Link>
}

export function UserPage({ userId }: { userId: string }) {
    return <KeyboardColumn>
        <h2>User {userId}</h2>
        <UserProjects userId={userId} />
    </KeyboardColumn>
}
