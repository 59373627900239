import * as React from 'react';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

export const firebaseAuthUI = new firebaseui.auth.AuthUI(firebase.auth());

interface FirebaseLoginProps {
    onSignedIn?: (authRes: firebase.auth.UserCredential) => void;
}

export function FirebaseLogin({ onSignedIn }: FirebaseLoginProps) {
    const div = React.useRef<HTMLDivElement>(null);
    const [expand, setExpand] = React.useState(false);
    React.useEffect(() => {
        if (div.current) {
            firebaseAuthUI.start(div.current, {
                signInOptions: expand ? [
                    firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                ] : [
                    firebase.auth.EmailAuthProvider.PROVIDER_ID
                ],
                signInFlow: 'popup',
                credentialHelper: firebaseui.auth.CredentialHelper.NONE,
                callbacks: {
                    signInSuccessWithAuthResult: user => { if (onSignedIn) { onSignedIn(user); } return false; }
                }
            });
        }
    }, [expand, div, onSignedIn]);
    return <div>
        <div ref={div} />
        {!expand && <button onClick={() => setExpand(true)}>Show more sign in options</button>}
    </div>
}
