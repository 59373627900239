import './index.css';
import React from "react";
import ReactDOM from "react-dom";

export function ScreensRoot() {
    return <div id="ScreensRoot" />;
}

export type ScreenType = React.ReactNode | null;
export type SetScreen = (screen: ScreenType) => void;

export function ScreenContainer({ screen }: { screen: ScreenType }) {
    if (!screen) {
        return null;
    } else {
        return ReactDOM.createPortal(screen, document.getElementById("ScreensRoot")!);
    }
}

export function DialogScreen({ onClose = () => { }, children }: { onClose?: () => void, children?: React.ReactNode }) {
    return <div className="DialogScreen" onClick={e => { e.stopPropagation(); onClose(); }}>{children}</div>
}
