import { dbECSComponentDefaultValue, DbECSComponent, DbId } from "dims-shared";
import { doc } from "firebase/firestore";
import { useState, useMemo } from "react";
import { CheckboxEditor, EnumEditor, NumberEditor, NumberEditorType, StringEditor } from ".";
import { useStreamQuery, queryT, collections, useStreamDocument } from "../../FirebaseHooks";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { KeyboardColumn, KeyboardRow } from "../Layout";
import { Loading } from "../Loading";
import { ScreenType, ScreenContainer, DialogScreen } from "../Screen";

interface EntityData {
    [component: string]: any
}

export function ECSEntityDataEditor({ value = {}, onChange }: { value: EntityData, onChange: (value: EntityData) => void }) {
    let [screen, setScreen] = useState<ScreenType>(null);
    let addComponent = () => {
        setScreen(<SelectComponentDialog onSelect={comp => {
            if (comp) {
                onChange({ ...value, [comp.id]: dbECSComponentDefaultValue(comp.type) })
            }
            setScreen(null);
        }} />)
    }
    return <KeyboardColumn>
        <ScreenContainer screen={screen} />
        {Object.keys(value).map(key => {
            return <KeyboardRow>
                {key}: <ECSComponentValueEditor value={value[key]} onChange={v => onChange({ ...value, [key]: v })} componentId={key} />
                <Button onClick={() => {
                    let val = { ...value };
                    delete val[key];
                    onChange(val);
                }}><i className="fa-solid fa-trash"></i></Button>
            </KeyboardRow>
        })}
        <Button onClick={addComponent}>Add component</Button>
    </KeyboardColumn>
}
function SelectComponentDialog({ onSelect }: { onSelect: (id: (DbECSComponent & DbId) | null) => void }) {
    let [search, setSearch] = useState('');
    let components = useStreamQuery(useMemo(() => queryT(collections.ecsComponents), []));
    return <Loading values={{ components }}>{({ components }) => <DialogScreen onClose={() => onSelect(null)}>
        <Dialog>
            <KeyboardColumn style={{ alignItems: 'start' }}>
                <StringEditor value={search} onChange={setSearch} placeholder="Search for component" />
                {components
                    .filter(comp => (search.length === 0) || [comp.id, comp.name, comp.description].some(a => a.indexOf(search) !== -1))
                    .map(comp => <Button onClick={() => onSelect(comp)}>{comp.id}</Button>)}
            </KeyboardColumn>
        </Dialog>
    </DialogScreen>
    }</Loading>;
}
export function ECSComponentValueEditor({ value, onChange, componentId }: { value: any, onChange: (value: any) => void, componentId: string }) {
    let component = useStreamDocument(doc(collections.ecsComponents, componentId));
    return <Loading values={{ component }}>{({ component }) => {
        let type = component.type;
        switch (type.type) {
            case 'Bool': return <CheckboxEditor value={value} onChange={onChange} />;
            case 'Empty': return <div>()</div>;
            case 'F32': return <NumberEditor value={value} onChange={onChange} type={NumberEditorType.Float} />;
            case 'F64': return <NumberEditor value={value} onChange={onChange} type={NumberEditorType.Float} />;
            case 'I32': return <NumberEditor value={value} onChange={onChange} type={NumberEditorType.SignedInteger} />;
            case 'U32': return <NumberEditor value={value} onChange={onChange} type={NumberEditorType.UnsignedInteger} />;

            case 'String':
                return <StringEditor value={value} onChange={onChange} />;
            case 'ObjectRef': {
                return <StringEditor value={value.id || ""} onChange={value => onChange({id: value})} />;
            }
            case 'EntityUid':
                return <StringEditor value={value} onChange={onChange} />;

            default: {
                if (typeof type === 'object') {
                    switch ((type as any).type) {
                        case 'Enum': {
                            let variants = ((type as any).variants as string[]).reduce((p, x) => ({ ...p, [x]: {} }), {});
                            return <EnumEditor value={value} onChange={onChange} variants={variants} />;
                        }
                    }
                }
                return <i>Not implemented yet</i>
            }
        }
    }}</Loading>
}
