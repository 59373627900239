import { getApp } from "firebase/app";
import { ref, getDownloadURL, StorageReference, uploadString, getStorage } from "firebase/storage";
import { useState, useMemo, useEffect } from "react";
import { Loadable } from "../../Util";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { EditorType, AnyEditor, EditorFields, editorOption } from "../Editor";
import { KeyboardColumn, KeyboardRow } from "../Layout";
import { SetScreen, DialogScreen } from "../Screen";
import { CategoriesEditor } from "./Categories";

const storage = getStorage(getApp(), "gs://dims-assets");

const PipelinePbrMaterialFields: EditorFields = {
    name: { type: editorOption(() => '', { type: 'string' }) },
    source: { type: editorOption(() => '', { type: 'string' }) },
    base_color: { type: editorOption(() => '', { type: 'string' }) },
    opacity: { type: editorOption(() => '', { type: 'string' }) },
    // base_color_factor: Option<Vec4>,
    // emissive_factor: Option<Vec4>,
    normalmap: { type: editorOption(() => '', { type: 'string' }) },
    transparent: { type: editorOption(() => false, { type: 'bool' }) },
    alpha_cutoff: { type: editorOption(() => 0.5, { type: 'float' }) },
    double_sided: { type: editorOption(() => false, { type: 'bool' }) },
    metallic_roughness: { type: editorOption(() => '', { type: 'string' }) },
    metallic: { type: editorOption(() => 0.5, { type: 'float' }) },
    roughness: { type: editorOption(() => 0.5, { type: 'float' }) },

    // Non-pbr properties that gets translated to pbr
    specular: { type: editorOption(() => '', { type: 'string' }) },
    specular_exponent: { type: editorOption(() => 1, { type: 'float' }) },
}
const Vec3Type: EditorType = {
    type: 'struct',
    fields: {
        x: { type: { type: 'float' } },
        y: { type: { type: 'float' } },
        z: { type: { type: 'float' } },
    }
}
const ModelTransformType: EditorType = {
    type: 'enum-struct',
    variants: {
        RotateYUpToZUp: {},
        RotateX: { fields: { deg: { type: { type: 'float' } } } },
        RotateY: { fields: { deg: { type: { type: 'float' } } } },
        RotateZ: { fields: { deg: { type: { type: 'float' } } } },
        Scale: { fields: { scale: { type: { type: 'float' } } } },
        Translate: { fields: { translation: { type: Vec3Type } } },
        ScaleAABB: { fields: { scale: { type: { type: 'float' } } } },
        ScaleAnimations: { fields: { scale: { type: { type: 'float' } } } },
        SetRoot: { fields: { name: { type: { type: 'string' } } } },
        Center: {},
    }
}

const PipelineConfig: EditorType = {
    type: 'enum-struct',
    variants: {
        Models: {
            default: () => ({
                importer: { type: 'Regular' },
                sources: [],
                collider: { type: 'FromModel' },
                collider_type: 'Static',
            }),
            fields: {
                importer: {
                    type: {
                        type: 'enum-struct',
                        variants: {
                            Regular: { default: () => ({}), fields: {} },
                            UnityModels: { default: () => ({ use_prefabs: true }), fields: { use_prefabs: { type: { type: 'bool' } } } },
                            Quixel: { default: () => ({}), fields: {} },
                        }
                    }
                },
                force_assimp: {
                    type: { type: 'bool' },
                    name: 'Force assimp',
                    description: 'The assimp importer is more general and supports more formats, but fewer features'
                },
                collider: {
                    type: {
                        type: 'enum-struct',
                        variants: {
                            None: { default: () => ({}), fields: {} },
                            FromModel: { default: () => ({}), fields: {} },
                            Character: {
                                default: () => ({ radius: 0.5, height: 2. }), fields: {
                                    radius: { type: { type: 'float' } },
                                    height: { type: { type: 'float' } },
                                }
                            },
                        }
                    }
                },
                collider_type: {
                    type: {
                        type: 'enum',
                        variants: {
                            Static: {},
                            Dynamic: {},
                            TriggerArea: {},
                            Picking: {},
                        }
                    }
                },
                cap_texture_sizes: {
                    type: {
                        type: 'option',
                        innerType: {
                            type: 'enum',
                            variants: {
                                X128: {},
                                X256: {},
                                X512: {},
                                X1024: {},
                                X2048: {},
                                X4096: {},
                            }
                        },
                        defaultValue: () => 'X2048',
                    },
                    name: 'Cap texture sizes',
                    description: 'Any texture which is larger than the configured size will be resized to the configured size'
                },
                output_objects: {
                    type: {
                        type: 'bool'
                    },
                    name: 'Output objects'
                },
                output_animations: {
                    type: {
                        type: 'bool'
                    },
                    name: 'Output animations'
                },
                collection_of_variants: {
                    type: {
                        type: 'bool'
                    },
                    name: "Collection of variants",
                    description: "When this is true, a single collection asset will be outputted containing all assets generated in the pipeline"
                },
                material_overrides: {
                    type: {
                        type: 'list',
                        itemType: {
                            type: 'struct',
                            fields: {
                                filter: {
                                    type: {
                                        type: 'enum-struct',
                                        variants: {
                                            All: {},
                                            ByName: { fields: { name: { type: { type: 'string' } } } }
                                        }
                                    }
                                },
                                material: {
                                    type: {
                                        type: 'struct',
                                        fields: PipelinePbrMaterialFields
                                    }
                                }
                            }
                        },
                        newItem: () => ({
                            filter: { type: 'All' },
                            material: {}
                        })
                    }
                },
                transforms: {
                    type: {
                        type: 'list',
                        itemType: ModelTransformType,
                        newItem: () => ({ type: 'RotateYUpToZUp' })
                    }
                },
                object_components: {
                    type: { type: 'entity-data' }
                }
            }
        },
        ScriptBundles: {
            default: () => ({
                sources: []
            }),
            fields: {
            }
        },
        Materials: {
            default: () => ({
                importer: { type: 'Quixel' },
                output_decals: false,
                sources: []
            }),
            fields: {
                importer: {
                    type: {
                        type: 'enum-struct',
                        variants: {
                            Quixel: {},
                            Single: {
                                default: () => ({}),
                                fields: PipelinePbrMaterialFields,
                            }
                        }
                    }
                },
                output_decals: {
                    type: {
                        type: 'bool'
                    }
                }
            }
        },
        Audio: {
            default: () => ({
                sources: []
            }),
            fields: {
            }
        },
    }
};
const PipelineType: EditorType = {
    type: 'list',
    newItem: () => DEFAULT_PIPELINE,
    itemType: {
        type: 'struct',
        fields: {
            pipeline: { type: PipelineConfig },
            sources: {
                type: {
                    type: 'list',
                    itemType: { type: 'string' },
                    newItem: () => ''
                }
            },
            tags: {
                type: {
                    type: 'list',
                    newItem: () => '',
                    itemType: { type: 'string' }
                }
            },
            categories: {
                type: {
                    type: 'custom',
                    editor: (value, onChange) => <CategoriesEditor value={value} onChange={onChange} />
                }
            }
        }
    }
};
const DEFAULT_PIPELINE = {
    pipeline: {
        type: 'Models',
        importer: { type: 'Regular' },
        output_objects: true,
        output_animations: true,
        collider: { type: 'FromModel' },
    },
    sources: [],
};

export function EditPipelineScreen({ selectedPath, setScreen }: { selectedPath: string, setScreen: SetScreen }) {
    let [existing, setExisting] = useState<Loadable<any>>({ status: 'loading' });
    let storageRef = useMemo(() => ref(ref(storage, selectedPath), 'dims_pipeline.json'), [selectedPath]);
    useEffect(() => {
        (async () => {
            try {
                let url = await getDownloadURL(storageRef);
                let value = await (await fetch(url)).json();
                setExisting({ status: 'loaded', value });
            } catch (err) {
                setExisting({ status: 'error', error: '' });
            }
        })();
    }, [storageRef]);
    return <DialogScreen onClose={() => setScreen(null)}>
        <Dialog>
            {existing.status === 'loading' ? <span>Loading...</span> :
                existing.status === 'loaded' ? <EditPipelineScreenInner storageRef={storageRef} startValue={existing.value} setScreen={setScreen} /> :
                    <EditPipelineScreenInner storageRef={storageRef} startValue={[DEFAULT_PIPELINE]} setScreen={setScreen} />}
        </Dialog>
    </DialogScreen>
}
function EditPipelineScreenInner({ storageRef, startValue, setScreen }: { storageRef: StorageReference, startValue: any, setScreen: SetScreen }) {
    let [pipeline, setPipeline] = useState(startValue);
    let addPipeline = async () => {
        console.log('Saving pipeline', pipeline);
        await uploadString(storageRef, JSON.stringify(pipeline, null, 2));
        setScreen(null);
    };
    return <KeyboardColumn>
        <h3>Pipeline</h3>
        <AnyEditor value={pipeline} onChange={setPipeline} type={PipelineType} />
        <KeyboardRow>
            <Button primary={true} onClick={addPipeline}>Save</Button>
            <Button onClick={() => setScreen(null)}>Cancel</Button>
        </KeyboardRow>
    </KeyboardColumn>;
}
