import './index.css';
import { deleteObject, FirebaseStorage, getDownloadURL, list, listAll, ListResult, ref, StorageReference, uploadBytes } from "firebase/storage";
import { useEffect, useMemo, useState } from "react";
import { useListAllFiles } from "../../FirebaseHooks";
import { arrayInsertIndex, Loadable } from "../../Util";
import { Button } from "../Button";
import { CheckboxEditor } from "../Editor";
import { KeyboardColumn, KeyboardRow } from "../Layout";
import { Loading } from "../Loading";
import { toByteUnit } from '../Misc';
import { PromptingButton } from '../Dialog/Buttons';

export function StorageBrowser({ storageRef, storage, selectedPath, setSelectedPath, refreshFilesToken, editable = true }: { storageRef: StorageReference, storage: FirebaseStorage, selectedPath: string, setSelectedPath: (path: string) => void, refreshFilesToken: {}, editable?: boolean }) {
    let [filesRefreshToken, setFilesRefreshToken] = useState({});
    let [selectedDirs, setSelectedDirs] = useState<{ [key: string]: boolean }>({});
    let [selectedFiles, setSelectedFiles] = useState<{ [key: string]: boolean }>({});
    let updateSelectedPath = (path: string) => {
        setSelectedDirs({});
        setSelectedFiles({});
        setSelectedPath(path);
    }
    let nSelectedDirs = Object.values(selectedDirs).reduce((p, x) => p + (x ? 1 : 0), 0);
    let nSelectedFiles = Object.values(selectedFiles).reduce((p, x) => p + (x ? 1 : 0), 0);
    let [deletingStatus, setDeletingStatus] = useState<null | string>(null);
    let deleteSelected = async () => {
        for (const [file, checked] of Object.entries(selectedFiles)) {
            if (checked) {
                console.log('deleting file', file);
                await deleteObject(ref(storage, file));
                setFilesRefreshToken({});
            }
        }
        for (const [dir, checked] of Object.entries(selectedDirs)) {
            if (checked) {
                await deleteRecursive(ref(storage, dir), file => {
                    setDeletingStatus(`Deleting ${file.name}`);
                    setFilesRefreshToken({});
                });
                setFilesRefreshToken({});
            }
        }
        setDeletingStatus(null);
        setSelectedDirs({});
        setSelectedFiles({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let filesStorageRef = useMemo(() => ref(storage, selectedPath), [storage, selectedPath, filesRefreshToken, refreshFilesToken]);

    return <Loading values={{ files: useListFiles(filesStorageRef) }}>{({ files }) => {
        let displayPath = selectedPath.slice(storageRef.fullPath.length + 1);
        let pathSegments = [{ name: <i className="fa-solid fa-folder-open"></i>, path: storageRef.fullPath }];
        for (const segment of displayPath.split('/')) {
            pathSegments.push({ name: <span>{segment}</span>, path: pathSegments[pathSegments.length - 1].path + '/' + segment });
        }
        const inner = <>
            <KeyboardRow>
                {editable && <><Button disabled={nSelectedDirs === 0 && nSelectedFiles === 0} flat={true} onClick={deleteSelected}>
                    <i className="fa-solid fa-trash"></i>
                </Button>
                    |
                </>}
                {pathSegments.map<React.ReactNode>(item =>
                    <Button key={item.path} flat={true} onClick={() => updateSelectedPath(item.path)}>{item.name}</Button>)
                    .reduce((prev, cur) => [prev, <span>/</span>, cur])}
                <PromptingButton flat={true} defaultValue={displayPath} onOk={path => updateSelectedPath(`${storageRef.fullPath}/${path}`)} title="Enter path">
                    <i className="fa-solid fa-pen"></i>
                </PromptingButton>
            </KeyboardRow>
            <KeyboardColumn style={{ overflowY: 'scroll', alignItems: 'start' }}>
                {files.prefixes.map(folder => <KeyboardRow key={folder.fullPath}>
                    {editable && <CheckboxEditor value={selectedDirs[folder.fullPath]} onChange={checked => setSelectedDirs({ ...selectedDirs, [folder.fullPath]: checked })} />}
                    <Button
                        flat={true}
                        onClick={() => updateSelectedPath(folder.fullPath)}
                    ><i className="fa-solid fa-folder-open"></i> {folder.name}</Button>
                </KeyboardRow>)}
                {files.items.map(file => <KeyboardRow key={file.fullPath}>
                    <CheckboxEditor value={selectedFiles[file.fullPath]} onChange={checked => setSelectedFiles({ ...selectedFiles, [file.fullPath]: checked })} />
                    {file.name}
                    <Button flat={true} onClick={async () => {
                        let url = await getDownloadURL(file);
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = file.name;
                        link.click();
                    }}><i className="fa-solid fa-circle-down"></i></Button>
                </KeyboardRow>)}
                {files.prefixes.length === 0 && files.items.length === 0 && <i>Drop files here to upload</i>}
                {deletingStatus}
            </KeyboardColumn>
        </>;
        if (editable) {
            return <FileDropArea storage={storage} selectedPath={selectedPath} onUploaded={() => setFilesRefreshToken({})}>
                {inner}
            </FileDropArea>;
        } else {
            return inner;
        }

    }}</Loading>
}

function FileDropArea({ storage, selectedPath, onUploaded, children }: { storage: FirebaseStorage, selectedPath: string, onUploaded: () => void, children?: React.ReactNode }) {

    let [isDragOver, setIsDragOver] = useState(false);
    let [uploading, setUploading] = useState<null | string[]>(null);
    let [globalStatus, setGlobalStatus] = useState<null | string>(null);
    let handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(true);
    };
    let handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false);
        let pendingFiles = [];
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
            let entry = event.dataTransfer.items[i].webkitGetAsEntry();
            if (entry) {
                pendingFiles.push(getFilesRecursive(entry, ref(storage, selectedPath)));
            }
        }
        let files = (await Promise.all(pendingFiles)).flat();
        let totalSize = files.reduce((p, file) => p + file.file.size, 0);
        let totalFiles = files.length;
        let totalUploaded = { value: 0 };
        let doneSize = { value: 0 };
        let nWorkers = 5;
        let status = new Array(nWorkers).fill(0).map(() => 'Starting...');
        let updateGlobalStatus = () => {
            setGlobalStatus(`Uploading ${toByteUnit(doneSize.value)}/${toByteUnit(totalSize)}: ${Math.floor(doneSize.value * 100 / totalSize)}%`);
        }
        await Promise.all(new Array(nWorkers).fill(0).map(async (_, w) => {
            while (files.length > 0) {
                let file = files.shift();
                if (file) {
                    updateGlobalStatus();
                    status[w] = `${totalUploaded.value + 1}/${totalFiles}: ${file.file.name} (${toByteUnit(file.file.size)})`;
                    setUploading(status);
                    await uploadBytes(file.storageRef, file.file);
                    console.log(file.storageRef.fullPath, file.file);
                    onUploaded();
                    doneSize.value += file.file.size;
                    totalUploaded.value += 1;
                }
            }
            updateGlobalStatus();
            status[w] = 'Done';
            setUploading(status);
        }));
        await new Promise(resolve => setTimeout(resolve, 2000));
        setUploading(null);
        setGlobalStatus(null);
    };
    return <div className={`KeyboardColumn ${isDragOver ? 'DragOver' : ''}`} style={{ alignItems: 'stretch', flex: 1, overflow: 'hidden' }}
        onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={() => setIsDragOver(true)} onDragLeave={() => setIsDragOver(false)}>
        {children}
        {globalStatus}
        {uploading && uploading.map((status, i) => <KeyboardRow key={i}>{status}</KeyboardRow>)}
    </div>;
}

async function getFilesRecursive(item: FileSystemEntry, storageRef: StorageReference): Promise<Array<{ file: File, storageRef: StorageReference }>> {
    if (item.isFile) {
        let fileEntry = item as FileSystemFileEntry;
        let file = await new Promise<File>((resolve, reject) => fileEntry.file(resolve, reject));
        return [{ file, storageRef: ref(storageRef, file.name) }];
    } else if (item.isDirectory) {
        let dir = item as FileSystemDirectoryEntry;
        let dirReader = dir.createReader();
        let results: Array<{ file: File, storageRef: StorageReference }> = [];
        let entries = await readAllDirEntries(dirReader);
        for (let i = 0; i < entries.length; i++) {
            let items = await getFilesRecursive(entries[i], ref(storageRef, item.name));
            results = [...results, ...items];
        }
        return results;
    } else {
        return [];
    }
}
async function readAllDirEntries(dirReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
    // Chrome only returns first 100; need to call multiple times to get all
    // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryReader/readEntries
    let entries = await new Promise<FileSystemEntry[]>((resolve, reject) => dirReader.readEntries(resolve, reject));
    if (entries.length === 0) {
        return [];
    } else {
        let moreEntries = await readAllDirEntries(dirReader);
        return [...entries, ...moreEntries];
    }
}

function useListFiles(storageRef: StorageReference | null) {
    let [files, setFiles] = useState<Loadable<ListResult>>({ status: 'loading' });
    useEffect(() => {
        (async () => {
            if (storageRef) {
                setFiles({ status: 'loaded', value: await listAll(storageRef) });
            } else {
                setFiles({ status: 'error', error: 'no-storage-ref' });
            }
        })();
    }, [storageRef]);
    return files;
}

export async function deleteRecursive(ref: StorageReference, onFileDeleting?: (file: StorageReference) => void) {
    let files = await listAll(ref);
    for (const file of files.items) {
        if (onFileDeleting) {
            onFileDeleting(file);
        }
        await deleteObject(file);
    }
    for (const dir of files.prefixes) {
        await deleteRecursive(dir, onFileDeleting);
    }
}
