import { useState } from "react";
import { getOS } from "../Util";
import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { KeyboardColumn, KeyboardRow } from "./Layout";
import { SetScreen, DialogScreen } from "./Screen";


export function DownloadLauncherDialog({ setScreen }: { setScreen: SetScreen }) {
    let [os, setOs] = useState<ReturnType<typeof getOS>>(getOS());
    let path = os === 'Windows' ? 'win/dims-launcher.exe' :
        os === 'Mac OS' ? 'osx-aarch64/DimsLauncher.app.zip' :
            os === 'Linux' ? 'linux/dims-launcher' :
                '';
    return <DialogScreen onClose={() => setScreen(null)}>
        <Dialog>
            <KeyboardColumn style={{ alignItems: 'start' }}>
                <h2>The Dims Launcher is not running</h2>
                <KeyboardRow>
                    OS:
                    <Button toggled={os === 'Windows'} onClick={() => setOs('Windows')}>Windows</Button>
                    <Button toggled={os === 'Mac OS'} onClick={() => setOs('Mac OS')}>MacOS</Button>
                    <Button toggled={os === 'Linux'} onClick={() => setOs('Linux')}>Linux</Button>
                </KeyboardRow>
                <a className='button' href={`https://dims-releases.fra1.digitaloceanspaces.com/launcher/${path}`}>Download the launcher</a>
            </KeyboardColumn>
        </Dialog>
    </DialogScreen>;
}
