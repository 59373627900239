import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
import oldFirebase from 'firebase/compat/app';

const config = {
    apiKey: "AIzaSyAnN44aDAlYZ0vAc71i9BwOWUVXTXSEc-M",
    authDomain: "dims-web.firebaseapp.com",
    projectId: "dims-web",
    storageBucket: "dims-web.appspot.com",
    messagingSenderId: "655674764934",
    appId: "1:655674764934:web:a7fed708b216b1ba73eb07",
    measurementId: "G-V2Q91HXTJF"
};
const app = firebase.initializeApp(config);
getAnalytics(app);
oldFirebase.initializeApp(config); // This is only needed to get firebaseui to work: https://github.com/firebase/firebaseui-web/issues/837
