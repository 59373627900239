import { useState } from "react";
import { Confirm, Prompt } from ".";
import { Button, ButtonProps } from "../Button";
import { ScreenType, ScreenContainer, SetScreen } from "../Screen";

export interface ConfirmingButtonProps extends ButtonProps {
    onClick?: () => void | Promise<void>;
    confirm?: string,
}
export function ConfirmingButton({ onClick, confirm = 'Are you sure?', children, ...props }: ConfirmingButtonProps) {
    let [screen, setScreen] = useState<ScreenType>(null);
    return <Button onClick={confirmClick(setScreen, onClick, confirm)} {...props}>
        <ScreenContainer screen={screen} />
        {children}
    </Button>
}
export function confirmClick(setScreen: SetScreen, onClick: (() => void | Promise<void>) | undefined, title: string = 'Are you sure?') {
    return !onClick ? undefined : async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setScreen(<Confirm setScreen={setScreen} title={title} onOk={onClick} />);
    };
}


export interface PromptingButtonProps extends ButtonProps {
    onOk?: (value: string) => void | Promise<void>;
    title?: string,
    defaultValue?: string,
}
export function PromptingButton({ onOk, title = 'Enter value', defaultValue, children, ...props }: PromptingButtonProps) {
    let [screen, setScreen] = useState<ScreenType>(null);
    let handleClick = !onOk ? undefined : async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setScreen(<Prompt setScreen={setScreen} title={title} defaultValue={defaultValue} onOk={onOk} />);
    };
    return <Button onClick={handleClick} {...props}>
        <ScreenContainer screen={screen} />
        {children}
    </Button>
}

export interface EditableStringProps {
    onOk: (value: string) => void | Promise<void>,
    children?: string,
    editable?: boolean,
    title?: string,
    placeholder?: string,
}
export function EditableString({ onOk, children, editable = true, title = 'Enter value', placeholder }: EditableStringProps) {
    let [screen, setScreen] = useState<ScreenType>(null);
    if (editable) {
        let handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            setScreen(<Prompt setScreen={setScreen} title={title} defaultValue={children} onOk={onOk} />);
        };
        return <div className="EditableString" onClick={handleClick}>
            <ScreenContainer screen={screen} />
            {children ? children : placeholder ? <i>{placeholder}</i> : null}
            <i className="fa-solid fa-pen"></i>
        </div>
    } else {
        return <>{children}</>;
    }
}
