import { firestore } from "firebase-admin";
import { v4 as uuidv4 } from 'uuid';
import * as basex from 'base-x';
import { Buffer } from 'buffer';
export const base62 = basex('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

export function base62uuid() {
    return base62.encode(uuidv4(null, Buffer.alloc(16)));
}

export enum Db {
    assetTags = 'assetTags',
    users = 'users',
    profiles = 'profiles',
    instances = 'instances',
    projects = 'projects',
    members = 'members',
    assetPacks = 'assetPacks',
    assets = 'assets',
    servers = 'servers',
    matchmakingQueues = 'matchmakingQueues',
    messages = 'messages',
    issues = 'issues',
    releases = 'releases',
    organizations = 'organizations',
    ecsComponents = 'ecsComponents',
    pipelineJobs = 'pipelineJobs',
    pipelineWorkers = 'pipelineWorkers',
    namespaces = 'namespaces'
}

export interface DbId {
    id: string
}


// Not editable by the user, visible to the user
// export interface DbUser {}

// Editable by the user, visible to all users
export interface DbProfile {
    displayName: string,
    imagePath?: string,
}

// Editable by the user, only visible to the user
// export interface DbSettings { }


export interface PlayConfigOneOff { type: 'one-off' };
export interface PlayConfigOfficialRealms { type: 'official-realms', playerLimit: number, realms: string[] };
export interface PlayConfigPlayerRealms { type: 'player-realms', playerLimit: number };
export interface PlayConfigMatchmaking { type: 'matchmaking', minPlayers: number, maxPlayers: number };
export interface PlayConfigCustomGame { type: 'custom-game', minPlayers: number, maxPlayers: number };
export type PlayConfig =
    PlayConfigOneOff |
    PlayConfigOfficialRealms |
    PlayConfigPlayerRealms |
    PlayConfigMatchmaking |
    PlayConfigCustomGame;
export const PlayConfigTypes: Array<PlayConfig['type']> = ['one-off', 'official-realms', 'player-realms', 'matchmaking', 'custom-game'];
export const PlayConfigDescriptions: { [key in PlayConfig['type']]: string } = {
    'one-off': 'Instances are created as short-lived, single-player instances. After the player leaves, the instance is destroyed',
    'official-realms': 'You provide a list of official realms, which each is persistent',
    'player-realms': 'Players can create their own persistent realms, and invite their friends to them',
    'matchmaking': 'Players join a queue when they want to play, and are grouped with other players to play one or multiple matches',
    'custom-game': 'Players can create short-lived instances, and send links to those instances to other players'
};

export interface DbProject {
    name: string,
    namespace: string,
    appVersion: string,
    isCreated: boolean,
    description: string,
    playConfig: PlayConfig,
    releaseId?: string,
}
export type DbMemberType = 'project' | 'organization';
export interface DbMember {
    type: DbMemberType,
    itemId: string,
    userId: string,
    isAdmin: boolean,
    created: firestore.Timestamp,
    sidebarOrder: number,
}
export function stringifyMemberId(type: DbMemberType, itemId: string, userId: string) {
    return `${type}-${itemId}-${userId}`;
}

export type GameMode = 'Edit' | 'Play';

export interface DbMatchmakingQueue {
    projectId: string,
    users: string[],
    starting: boolean,
    instance?: string,
}

export interface DbInstance {
    projectId: string,
    created: firestore.Timestamp,
    stopped?: firestore.Timestamp,
    host: string,
    port: number,
    appVersion: string,
    state: 'starting' | 'running' | 'exited',
    exitCode?: number,
    mode: GameMode,
}
export interface DbServer {
    publicIp: string;
    instances: {
        [port: string]: string
    },
    dropletId: string,
    volumeId: string,
}

export interface DbRelease {
    projectId: string,
    created: firestore.Timestamp,
    appVersion: string,
    version: string,
    title: string,
}

export interface DbMessage {
    userId: string,
    content: string,
    created: firestore.Timestamp,
}

export interface DbPipelineJob {
    created: firestore.Timestamp,
    ownerId: string,
    assetPackId: string,
    state: 'queued' | 'running' | 'completed',
    started?: firestore.Timestamp,
    statusTime: firestore.Timestamp,
    status: string,
    errors?: string[],
    exitCode?: number,
    completed?: firestore.Timestamp,
}
export interface DbPipelineWorker {
    state: 'running' | 'idle',
    jobId?: string | null,
    updated?: firestore.Timestamp
}

export interface DbAssetPack {
    name: string,
    created: firestore.Timestamp,
    ownerId: string,
    public: boolean,
    versions: string[],
    deleted: boolean,
}
export type OwnerType = 'org' | 'user' | 'prj' | 'sys';
export function stringifyOwnerId(type: OwnerType, id: string) {
    return `${type}:${id}`;
}
export function parseOwnerId(id: string): { type: OwnerType, id: string } {
    let ss = id.split(':');
    return { type: ss[0] as OwnerType, id: ss[1] };
}
export function isAssetPackOwner(assetPackOwnerId: string, userId: string, memberships: DbMember[]) {
    let { type, id } = parseOwnerId(assetPackOwnerId);
    if (type === 'user') {
        return id === userId;
    } else if (type === 'org') {
        for (const member of memberships) {
            if (member.type === 'organization' && member.itemId === id) {
                return true;
            }
        }
    } else if (type === 'prj') {
        for (const member of memberships) {
            if (member.type === 'project' && member.itemId === id) {
                return true;
            }
        }
    }
    return false;
}

export interface DbAsset {
    type: 'Animation',
    created: firestore.Timestamp,
    assetPackId: string,
    ownerId: string,
    public: boolean,
    version: string,
    appVersion: string,
    hidden: boolean,
    isCollection: boolean,
    name: string,
    source?: string,
    previewUrl: string | null,
    categories_lvl0?: string | string[],
    categories_lvl1?: string | string[],
    categories_lvl2?: string | string[],
    tags: string[],

    content?: string,
    collection?: string[],

    // thumbnailUrl?: string,
    // sourceType: 'assetPack',
    // sourceId: string,
    // sourceVersion: string,
    // created: firestore.Timestamp,
    // appVersion: string,
    // contentUrl?: string,
}


export interface DbIssue {
    created: firestore.Timestamp,
    title: string,
    ownerId: string,
}

export interface DbOrganization {
    created: firestore.Timestamp,
    name: string,
    namespace: string,
    assetTags: string[],
}

export interface DbNamespace {
    ownerId: string,
}

export type DbECSComponentPrimitiveType =
    | "Empty"
    | "Bool"
    | "EntityId"
    | "F32"
    | "F64"
    | "Mat4"
    | "I32"
    | "Quat"
    | "String"
    | "U32"
    | "U64"
    | "Vec2"
    | "Vec3"
    | "Vec4"
    | "ObjectRef"
    | "EntityUid";

export type DbECSComponentType =
    | Distribute<DbECSComponentPrimitiveType>
    | { type: 'Enum', variants: string[] }
    | { type: 'Vec', variants: { type: DbECSComponentPrimitiveType } }
    | { type: 'Option', variants: { type: DbECSComponentPrimitiveType } };

export function dbECSComponentDefaultValue(type: DbECSComponentType) {
    switch (type.type) {
        case "Empty": return null;
        case "Bool": return true;
        case "EntityId": return "";
        case "F32": return 0;
        case "F64": return 0;
        case "Mat4": return [];
        case "I32": return 0;
        case "Quat": return [];
        case "String": return "";
        case "U32": return 0;
        case "U64": return 0;
        case "Vec2": return [];
        case "Vec3": return [];
        case "Vec4": return [];
        case "ObjectRef": return { id: "" };
        case "EntityUid": return "";
        default: {
            switch (type.type) {
                case 'Enum': return type.variants[0];
                case 'Vec': return [];
                case 'Option': return null;
            }
        }
    }
}

export interface DbECSComponent {
    created?: firestore.Timestamp;
    deleted: boolean,
    description: string,
    name: string,
    ownerId: string,
    public: boolean,
    type: DbECSComponentType,
}

export interface ReqStartGameInstance {
    projectId: string,
    mode: GameMode,
}
export type RespStartGameInstance = {
    status: 'failed',
} | {
    status: 'ok',
    instanceId: string,
    host: string,
    port: number
}

export interface ReqCloneProjectFiles {
    fromId: string,
    toId: string
}
export interface ReqCreateRelease {
    projectId: string,
    version: string
}
export interface ReqResetToRelease {
    projectId: string,
    version: string
}

export function partial<T>(data: Partial<T>) {
    return data;
}
export function full<T>(data: T) {
    return data;
}
export function partialKeys<T>(data: { [P in keyof Partial<T>]: any }) {
    return data;
}

export function isNullOrUndefined<T>(v: T | undefined | null): v is undefined | null {
    return v === undefined || v === null;
}

// https://github.com/microsoft/TypeScript/issues/20707
export function notNullOrUndefined<T>(x: T | undefined | null): x is T {
    return x !== undefined && x !== null;
}
export function notFalsy<T>(x: T | undefined | null | '' | false): x is T {
    return !!x;
}

// https://stackoverflow.com/a/51691257/4314212
type Distribute<U> = U extends any ? { type: U } : never;
