import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Button } from "../../Components/Button";
import { KeyboardColumn } from "../../Components/Layout";
import { Loading } from "../../Components/Loading";
import { collections, useStreamQuery } from "../../FirebaseHooks";


const functions = getFunctions(getApp());
const createGameServer = httpsCallable(functions, 'createGameServer');
const destroyGameServer = httpsCallable(functions, 'destroyGameServer');

export function AdminServers() {
    return <Loading values={{ servers: useStreamQuery(collections.servers) }}>{({ servers }) => <KeyboardColumn style={{ alignItems: 'start' }}>
        <Button onClick={async () => { await createGameServer(); }}>Create</Button>
        <table>
            <thead>
                <tr>
                    <th>Ip</th>
                    <th>Instances</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {servers.map(server => <tr key={server.id}>
                    <td>{server.publicIp}</td>
                    <td>{Object.keys(server.instances).length}</td>
                    <td>
                        <Button onClick={async () => { await destroyGameServer({ id: server.id }); }}>Destroy</Button>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </KeyboardColumn>}</Loading>
}
